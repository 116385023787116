import {InfoCircleOutlined, MinusSquareOutlined, PlusSquareOutlined} from '@ant-design/icons';
import {GetNextPageParamFunction, useInfiniteQuery} from '@tanstack/react-query';
import {
  ColumnDef,
  GroupColumnDef,
  Header,
  Row,
  RowSelectionState,
  SortDirection,
  SortingState,
  Table,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {ColumnSort} from '@tanstack/table-core/src/features/Sorting';
import {Button, Checkbox, Tooltip, notification} from 'antd';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Uuid} from '../../../src/models/types';
import {DbFilterState} from '../../../src/redux/reducers/filters';
import {ApisContext} from '../apis/ApisContext';
import '../components/InfinityTable.css';
import {disableSelectAllButton} from '../list/util';
import {State} from '../redux';
import {ErrorBoundary} from '../util/ErrorBoundary';
import {reportErr} from '../util/err';
import {useIsVisible} from '../util/hooks';
import {NoDataAlert} from './NoDataAlert';
import SpinningDots from './SpinningDots';

// Implementation of an infinite table. Pre-infused with access to the DbFilterState. As such this implementation is not
// fully generic, but rather focused to work well for the list/{entity} tables.
//
// The actual data fetching is delegated to the caller (`fetchData`). But InfinityTable takes care of triggering
// fetching further data sets upon scrolling.
//
// The caller may provide placeholder data to show before real data could be loaded.

export interface InfinityTableProps<TData, Q extends AdditionalQueryKeys = []> {
  columns: ColumnDef<TData, any>[];
  initialSorting: ColumnSort;
  tableId: string; // Used for caching, must be unique.
  // Fake/Mock data, rendered before actual/real data is available. We leave the definition to the user, to provide
  // data that closely matches the shape (length, distribution, etc) of the real data. This will insert the data as is
  // into the DOM and mask it for the user. But could be retrieved from the DOM.
  // Don't use sensible data as placeholder!
  placeholderData?: TData[];
  fetchData: (options: FetchDataOptions) => Promise<TData[]>; // Function to repeatedly fetch paged data.
  fetchSetSize: number; // How many rows to fetch in each call.
  getRowId: (row: TData) => string;
  getSortValue: (row: TData, columnId: any) => string | null;
  renderDetails?: React.FC<{row: Row<TData>}>;
  indentDetails?: number; // By how many columns should the details be indented?
  onRowSelectionChange?: (selection: TData[]) => void;
  onNumberOfDataItemsChange?: (numberOfDataItems: number) => void;
  additionalQueryKeys?: Q;
  allowSelectAll?: boolean;
}

export interface FetchDataOptions {
  orderBy?: {id: string; desc: boolean};
  filters: DbFilterState;
  pageParam?: ContinueParam;
}

interface ContinueParam {
  continue_from_val?: string | null;
  continue_from_id?: Uuid | null;
}

type AdditionalQueryKeys = (string | number | boolean | null)[];

interface ColDef {
  width: number;
  type: 'relative' | 'fixed';
}

function isColDef(obj: any): obj is ColDef {
  return obj && obj.width && obj.type;
}

// Get information used for column sizing. For multi-level headers, we have leaf <col/> elements as children of
// the root <colgroup/> element.
// Html does not support nested <colgroup/>s, thus we only have at most one level of nesting.
function getColGroups<TData>(table: Table<TData>): (ColDef | {children: ColDef[]})[] {
  const totalWidth = table
    .getVisibleLeafColumns()
    .reduce((acc, c) => acc + (c.columnDef.meta?.isFixedWidthColumn ? 0 : c.getSize()), 0);
  if (table.getHeaderGroups().length > 1) {
    return table
      .getAllColumns()
      .filter(c => c.depth === 0)
      .map(rc => ({
        children: rc
          .getLeafColumns()
          .filter(lc => lc.getIsVisible())
          .map(lc =>
            lc.columnDef.meta?.isFixedWidthColumn
              ? {width: lc.getSize(), type: 'fixed'}
              : {width: (lc.getSize() / totalWidth) * 100, type: 'relative'},
          ),
      }));
  } else {
    return table
      .getVisibleLeafColumns()
      .map(c =>
        c.columnDef.meta?.isFixedWidthColumn
          ? {width: c.getSize(), type: 'fixed'}
          : {width: (c.getSize() / totalWidth) * 100, type: 'relative'},
      );
  }
}

export function InfinityTable<TData extends Record<string, any>, Q extends AdditionalQueryKeys = []>(
  props: InfinityTableProps<TData, Q>,
) {
  const {
    onNumberOfDataItemsChange,
    onRowSelectionChange,
    getRowId,
    columns,
    fetchData,
    tableId,
    additionalQueryKeys,
    allowSelectAll,
    getSortValue,
    initialSorting,
    fetchSetSize,
    indentDetails,
    renderDetails,
  } = props;
  const filters = useSelector<State, DbFilterState>(state => state.filters);
  const [sorting, setSorting] = useState<SortingState>([initialSorting]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const retryCounter = useRef<number>(0);
  const dataLength = useRef<number>(0);

  const getNextPageParam: GetNextPageParamFunction<TData[]> = useCallback(
    (lastPage): ContinueParam | undefined => {
      if (lastPage.length < fetchSetSize || lastPage.length === 0) {
        // End of data reached.
        return undefined;
      }
      const {id: columnId} = sorting.length === 1 ? sorting[0] : initialSorting;
      const lastItem: TData | undefined = lastPage[lastPage.length - 1];

      const continue_from_val = getSortValue(lastItem, columnId);
      const hasContinueFromVal = continue_from_val !== undefined;
      const continue_from_id = getRowId(lastItem);
      if ((continue_from_id || hasContinueFromVal) && !(continue_from_id && hasContinueFromVal)) {
        // id XOR val
        // We cannot recover from this situation.
        throw new Error('Both continue_from_id and continue_from_val must be set or unset.');
      }
      return {
        continue_from_val,
        continue_from_id,
      };
    },
    [fetchSetSize, getRowId, getSortValue, initialSorting, sorting],
  );

  const {data, fetchNextPage, isInitialLoading, isSuccess, isFetching, error, hasNextPage} = useInfiniteQuery<
    TData[],
    unknown,
    TData[],
    [string, SortingState, DbFilterState, Q | undefined]
  >(
    [tableId, sorting, filters, additionalQueryKeys],
    async ({queryKey: [_, sorting, filters], pageParam}) => {
      const req: FetchDataOptions = {
        orderBy:
          sorting.length >= 0
            ? {
                id: sorting[0].id,
                desc: sorting[0].desc,
              }
            : undefined,
        filters,
        pageParam,
      };
      return fetchData(req).then(
        data => data,
        error => {
          // It would be interesting to do this on the QueryClient level, e.g. for all queries using onSettled. But
          // onSettled does not contain any information on the query that was settled. Which would make writing a
          // meaningful report (containing information on the source query) impossible. Thus, we catch the error here,
          // where we have access to information on which query is actually failing.
          if (error) {
            reportErr(error, 'infinity-table-' + tableId);
          }
          throw error;
        },
      );
    },
    {
      getNextPageParam: getNextPageParam,
      refetchOnWindowFocus: false,
      keepPreviousData: false, // This will clear the list on filter changes. But keep the data when paginating.
      refetchOnMount: 'always',
      // As we are catching the failed query inside useInfiniteQuery, we will report an error on each retry. Contrary to
      // onSettled, which would only be called after all retries failed. Reducing retries to 1, will at most log 2 error
      // reports.
      retry: 1,
    },
  );

  // We must flatten the array of arrays from the useInfiniteQuery hook.
  const flatData = React.useMemo(() => data?.pages?.flat(1) ?? [], [data]);

  useEffect(() => {
    if (onNumberOfDataItemsChange) {
      onNumberOfDataItemsChange(flatData.length);
    }
    // Need to return to top, else the screen might be empty (if scrolled down more than one page).
    // This happens e.g. when filters change / get more restrictive.
    if (dataLength.current > flatData.length) {
      window.scrollTo(0, 0);
    }
    dataLength.current = flatData.length;
  }, [onNumberOfDataItemsChange, flatData.length]);

  useEffect(() => {
    if (onRowSelectionChange) {
      const selectedRowIds: Set<string> = new Set(Object.keys(rowSelection));
      const selectedRows: TData[] =
        selectedRowIds.size !== 0 ? flatData.filter(d => selectedRowIds.has(getRowId(d))) : [];
      onRowSelectionChange(selectedRows);
    }
  }, [onRowSelectionChange, rowSelection, flatData, getRowId]);

  useEffect(() => {
    // Reset selection on sort changes. Else we might have selected items not visible without scrolling to the end of
    // infinity (which would be confusing for users).
    setRowSelection({});
  }, [sorting]);

  // Update column visibility, when the columns change. This enables us to reset the column visibility to the defined
  // values in the ColumnDef, whenever we get a new column configuration.
  useEffect(() => {
    setColumnVisibility(
      Object.fromEntries(
        columns.flatMap(c => [
          [c.id, !(c.meta?.initiallyHidden ?? false)],
          ...((c as GroupColumnDef<TData>).columns?.map(c => [c.id, !(c.meta?.initiallyHidden ?? false)]) ?? []),
        ]),
      ),
    );
  }, [columns]);

  const table = useReactTable<TData>({
    data: flatData,
    columns,
    state: {
      sorting,
      rowSelection,
      columnVisibility,
    },
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    enableMultiSort: false,
    enableSortingRemoval: true, // Unsorted queries require O(N^2) accesses for the N'th page - disable.
    manualSorting: true,
    manualFiltering: true,
    manualExpanding: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    // The default implementation of `getRowCanExpand`, used by `row.getCanExpand()`, requires `row.subRows` to exist.
    // As we slightly miss-use the expansion model here (and don't have any sub-rows), we need to customize this.
    // As `getRowCanExpand` takes precedence over the `enableExpanding` option, we can skip setting it.
    getRowCanExpand: () => isSuccess && !isFetching,
    enableRowSelection: isSuccess && !isFetching,
    getRowId: getRowId,
  });

  const {rows} = table.getRowModel();

  // Monitor the (filter) header for size changes, needed for the sticky table header.
  const {height: theadTop} = useResizeObserver('header.header');

  // Needed to modify the table styling while resizing.
  const isResizing = !!table.getState().columnSizingInfo.isResizingColumn;

  const onSort = useCallback((id: string, dir?: SortDirection) => {
    setSorting(s => {
      // Need to return to top, else the screen might be empty (if scrolled down more than one page), as sort will
      // return with only the first set of data loaded.
      window.scrollTo(0, 0);
      const desc = dir !== undefined ? dir === 'desc' : s[0].id === id ? !s[0].desc : false;
      return [{id, desc}];
    });
  }, []);

  const detailsIndent: number = indentDetails || 0;
  const numVisibleColumns = table.getVisibleLeafColumns().length;
  const cols = getColGroups(table);

  return (
    <div>
      <div className="container">
        <table className={`infinity-table ${isResizing ? 'resizing' : ''}`}>
          {cols.map((c, i) => {
            if (isColDef(c)) {
              return (
                <colgroup key={`group-${i}`}>
                  <col
                    key={i}
                    className={c.type}
                    style={{width: c.type === 'fixed' ? c.width.toFixed(0) + 'px' : c.width.toFixed(0) + '%'}}
                  />
                </colgroup>
              );
            } else {
              return (
                <colgroup key={i}>
                  {c.children.map((c, j) => (
                    <col
                      key={j}
                      className={c.type}
                      style={{width: c.type === 'fixed' ? c.width.toFixed(0) + 'px' : c.width.toFixed(0) + '%'}}
                    />
                  ))}
                </colgroup>
              );
            }
          })}
          <thead style={{top: theadTop}}>
            {table.getHeaderGroups().map((headerGroup, index, all) => {
              const visibleHeaders = headerGroup.headers.filter(h => index === 0 || h.column.getIsVisible());
              return (
                <tr key={headerGroup.id} className={getHeaderClass(index, all.length)}>
                  {visibleHeaders.map(header => (
                    <TableHeader<TData>
                      key={header.id}
                      header={header}
                      onSort={onSort}
                      isFetching={isFetching}
                      tableId={tableId}
                      disableSelectAll={disableSelectAllButton(flatData.length)}
                      isSelectAll={!!allowSelectAll && header.id === '__selection__'}
                      toggleAllRowsSelected={table.toggleAllRowsSelected}
                      getIsAllRowsSelected={table.getIsAllRowsSelected}
                    />
                  ))}
                </tr>
              );
            })}
            <tr>
              <th colSpan={numVisibleColumns} className="header-border" />
            </tr>
          </thead>
          <HasErrorBody
            error={error as Error | undefined}
            colSpan={numVisibleColumns}
            canRetry={retryCounter.current < 5}
            onRetry={() => retryCounter.current++}
          />
          <HasNoDataBody
            show={!isFetching && isSuccess && rows.length === 0}
            colSpan={numVisibleColumns}
            filters={filters}
          />
          <NonVirtualizedDataBody<TData>
            success={isSuccess}
            columns={columns}
            rows={rows}
            hasNextPage={hasNextPage}
            renderDetails={renderDetails}
            isInitialLoading={isInitialLoading}
            retryCounter={retryCounter.current}
            onShouldFetchNextPage={fetchNextPage}
            detailsIndent={detailsIndent}
          />
          <PlaceholderBody {...props} colSpan={numVisibleColumns} isFetching={isFetching} />
        </table>
      </div>
    </div>
  );

  function getHeaderClass(currentIndex: number, numHeaders: number): string {
    if (currentIndex === 0) {
      return 'root-headers';
    }
    if (currentIndex === numHeaders - 1) {
      return 'leaf-headers';
    }
    return '';
  }
}

interface TableHeaderProps<TData> {
  header: Header<TData, any>;
  onSort: (columnId: string, dir?: SortDirection) => void;
  isFetching: boolean;
  tableId: string;
  isSelectAll: boolean;
  disableSelectAll: boolean;
  toggleAllRowsSelected: (value?: boolean) => void;
  getIsAllRowsSelected: () => boolean;
}

const updateOnSortChangeAndResize = (a: TableHeaderProps<any>, b: TableHeaderProps<any>): boolean =>
  a.header.column.getIsSorted() !== b.header.column.getIsSorted() ||
  a.header.column.getSize() !== b.header.column.getSize() ||
  a.header.column.getIsResizing() !== b.header.column.getIsResizing();

const updateOnResize = (a: TableHeaderProps<any>, b: TableHeaderProps<any>): boolean =>
  a.header.column.getSize() !== b.header.column.getSize() ||
  a.header.column.getIsResizing() !== b.header.column.getIsResizing();

export const TableHeader: <TData>(props: TableHeaderProps<TData>) => React.ReactNode = React.memo(props => {
  const {analytics, t} = useContext(ApisContext);
  const {header, onSort, tableId} = props;
  const className = 'infinity-table-header ' + (header.column.getCanSort() ? 'cursor-pointer select-none' : '');
  const title: string =
    typeof header.column.columnDef.header === 'string' && header.column.getCanSort()
      ? t({
          type: 'SortBy',
          column: header.column.columnDef.header,
          id: header.column.getNextSortingOrder() === 'asc' ? 'Ascending' : 'Descending',
        })
      : (header.column.columnDef.header as string);
  const onSortToggle = useCallback(onSortToggleFn, [analytics, onSort, tableId, header]);
  // For reasons only the library author knows, header.subHeaders only contains headers for visible columns. We
  // could iterate over subHeader to hide visible headers, but not to show hidden header.
  const numSubColumns = header.column.getLeafColumns().length;
  const numHidableSubColumns =
    header.column.getLeafColumns().filter(c => c.id !== header.column.id && c.getCanHide()).length || 0;
  // Only check the first level of headers for hidable sub-headers.
  const hasHidableSubHeaders = header.depth === 1 && numHidableSubColumns > 0;
  const subHeadersAreVisible = numSubColumns > 0 && header.subHeaders.length === numSubColumns;
  const onToggleVisibility = useCallback(() => {
    header.column.getLeafColumns().forEach(sh => {
      if (sh.getCanHide()) {
        sh.toggleVisibility();
      }
    });
  }, [header]);
  const checkboxElement = (
    <Checkbox
      disabled={props.disableSelectAll}
      checked={props.getIsAllRowsSelected()}
      onChange={() => props.toggleAllRowsSelected()}
    />
  );
  return (
    <th colSpan={header.colSpan} title={title}>
      {header.isPlaceholder ? null : (
        <div className={className}>
          {hasHidableSubHeaders ? (
            <span onClick={onToggleVisibility}>
              {subHeadersAreVisible ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
            </span>
          ) : null}
          {props.isSelectAll &&
            (props.disableSelectAll ? (
              <Tooltip title={t('CantSelectTooManyItems')}>{checkboxElement}</Tooltip>
            ) : (
              checkboxElement
            ))}
          {!props.isSelectAll && (
            <div
              className={`name ${header.column.getCanSort() ? 'sortable' : ''}`}
              onClick={hasHidableSubHeaders ? onToggleVisibility : onSortToggle}>
              {flexRender(header.column.columnDef.header, header.getContext())}
            </div>
          )}
          <TableHeaderInfo {...props} />
          <TableHeaderSort {...props} />
          <TableHeaderResize {...props} />
        </div>
      )}
    </th>
  );

  function onSortToggleFn(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) {
    if (header.column.getCanSort()) {
      e.preventDefault();
      analytics.logEvent({
        event_name: 'infinity-table/sort',
        props: {
          table_id: tableId,
          sort_column: header.column.id,
          sort_direction: 'asc',
        },
      });
      onSort(header.column.id);
    } else {
      analytics.logEvent({
        event_name: 'infinity-table/sort-try',
        props: {
          table_id: tableId,
          sort_column: header.column.id,
        },
      });
    }
  }
}, updateOnSortChangeAndResize);

const TableHeaderInfo: <TData>(props: TableHeaderProps<TData>) => React.ReactNode = React.memo(props => {
  const columnInfo = props.header.column.columnDef.meta?.columnInfo;
  if (!columnInfo) return null;
  const setting = {
    key: props.header.id,
    duration: 30,
    ...columnInfo,
  };
  return <InfoCircleOutlined className="info" onClick={() => notification.info(setting)} />;
});

// Table header <th> resize ui element.
const TableHeaderResize: <TData>(props: TableHeaderProps<TData>) => React.ReactNode = React.memo(props => {
  const {header} = props;
  const onResize = useCallback(
    (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      e.stopPropagation(); // Do not trigger sort. (Does not work!)
      return header.getResizeHandler()(e);
    },
    [header],
  );
  if (!header.column.getCanResize()) {
    return null;
  }

  return (
    <div
      onMouseDown={onResize}
      onTouchStart={onResize}
      className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
    />
  );
}, updateOnResize);

// Table header <th> sort ui element.
const TableHeaderSort: <TData>(props: TableHeaderProps<TData>) => React.ReactNode = React.memo(props => {
  const {header, onSort, isFetching} = props;
  const onSortAsc = useCallback(() => {
    onSort(header.column.id, 'asc');
  }, [header.column.id, onSort]);
  const onSortDesc = useCallback(() => {
    onSort(header.column.id, 'desc');
  }, [header.column.id, onSort]);
  if (isFetching || !header.column.getCanSort() || header.column.getIsResizing()) {
    return null;
  }
  const sortDirection = header.column.getIsSorted();
  const classAsc = sortDirection === 'asc' ? 'active' : '';
  const classDesc = sortDirection === 'desc' ? 'active' : '';
  const nextSortClass = sortDirection === 'asc' ? 'next-desc' : 'next-asc';
  return (
    <div className={`sort ${nextSortClass}`}>
      <div className={classAsc} onClick={onSortAsc}>
        ▲
      </div>
      <div className={classDesc} onClick={onSortDesc}>
        ▼
      </div>
    </div>
  );
}, updateOnSortChangeAndResize);

// A simple hook to observe Element size changes.
function useResizeObserver(selector: string): {width: number; height: number} {
  const [size, setSize] = useState({width: 0, height: 0});
  useEffect(() => {
    const element = document.querySelector(selector);
    if (!element) {
      console.warn('ResizeObserver needs a valid Element, received "%s", will not observe anything.', element);
      return;
    }
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.borderBoxSize?.length >= 1) {
          // Use future-proof API by default.
          const width = entry.borderBoxSize[0].inlineSize;
          const height = entry.borderBoxSize[0].blockSize;
          return setSize({width, height});
        } else if (entry.contentRect) {
          // Fallback to older API just in case.
          const width = entry.contentRect.width;
          const height = entry.contentRect.height;
          return setSize({width, height});
        }
      }
    });
    resizeObserver.observe(element);
    setSize({width: element.clientWidth, height: element.clientHeight});
    return () => resizeObserver.unobserve(element); // Make sure to return a function to unregister the observer.
  }, [selector]);
  return size;
}

export const defaultColumnSizes = {
  xxs: 30,
  xs: 80,
  s: 100,
  m: 150,
  l: 200,
  xl: undefined,
};

interface HasDataBody<TData> {
  success: any;
  columns: ColumnDef<TData, any>[];
  rows: Row<TData>[];
  onShouldFetchNextPage: () => void;
  hasNextPage?: boolean;
  isInitialLoading: boolean;
  retryCounter: number;
  renderDetails: InfinityTableProps<TData>['renderDetails'];
  detailsIndent: number;
}

function NonVirtualizedDataBody<TData>(props: HasDataBody<TData>): JSX.Element | null {
  const lastRow = useRef<HTMLTableRowElement | null>(null);
  const visible = useIsVisible(lastRow);
  // Fetch next set of rows when reaching the end of the already fetched rows.
  const {
    onShouldFetchNextPage,
    hasNextPage,
    isInitialLoading,
    retryCounter,
    rows,
    detailsIndent,
    // Capitalize because React treats lowercase tags as HTML elements
    renderDetails: RenderDetails,
    columns,
  } = props;
  const shouldFetchNextPage = (hasNextPage && !isInitialLoading && visible) || (retryCounter > 0 && retryCounter < 3);
  useEffect(() => {
    if (shouldFetchNextPage) {
      // noinspection JSIgnoredPromiseFromCall
      onShouldFetchNextPage();
    }
  }, [onShouldFetchNextPage, shouldFetchNextPage]);

  return (
    <tbody>
      {rows?.map((row, index) => {
        return (
          <React.Fragment key={row.id}>
            <ErrorBoundary hideErrorMessage={true}>
              <tr data-testid="data-row" className={index % 2 ? 'odd' : 'even'}>
                {row.getVisibleCells().map(cell => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
              {RenderDetails && row.getIsExpanded() && (
                <tr data-testid="expansion-row">
                  {detailsIndent > 0 && <td colSpan={detailsIndent}></td>}
                  <td colSpan={columns.length - detailsIndent}>
                    <RenderDetails row={row} />
                  </td>
                </tr>
              )}
            </ErrorBoundary>
          </React.Fragment>
        );
      })}
      <tr ref={lastRow}>
        <td colSpan={columns.length}></td>
      </tr>
    </tbody>
  );
}

function HasErrorBody(props: {error?: Error; colSpan: number; onRetry: () => void; canRetry: boolean}) {
  const {t} = useContext(ApisContext);
  if (!props.error) return null;
  return (
    <tbody>
      <tr>
        <td colSpan={props.colSpan} className="has-error">
          <div className="user-error">
            <h1>{t('UnknownErrorOccurred')}</h1>
            {props.canRetry && (
              <Button
                size="large"
                onClick={() => {
                  props.onRetry();
                }}>
                {t('YouMayTryAgain')}
              </Button>
            )}
            <details>
              <summary>{t('Details')}</summary>
              <p>{props.error.message}</p>
            </details>
          </div>
        </td>
      </tr>
    </tbody>
  );
}

function HasNoDataBody(props: {show: boolean; colSpan: number; filters: DbFilterState}) {
  if (!props.show) return null;

  return (
    <tbody>
      <tr>
        <td colSpan={props.colSpan} className="has-no-data">
          <NoDataAlert />
        </td>
      </tr>
    </tbody>
  );
}

type PlaceholderBodyProps<TData extends object> = InfinityTableProps<TData, AdditionalQueryKeys> & {
  colSpan: number;
  isFetching: boolean;
};

function PlaceholderBody<TData extends object>({
  columns,
  placeholderData,
  getRowId,
  colSpan,
  isFetching,
}: PlaceholderBodyProps<TData>): JSX.Element | null {
  const table = useReactTable<TData>({
    data: placeholderData ? placeholderData : [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId,
  });
  if (!isFetching) return null;
  if (!placeholderData || placeholderData.length === 0) {
    return (
      <tbody className="mask">
        <tr>
          <td colSpan={colSpan} className={isFetching ? 'loading-animation' : ''}>
            {isFetching && <SpinningDots size={40} />}
          </td>
        </tr>
      </tbody>
    );
  }
  const {rows} = table.getRowModel();
  return (
    <tbody className="mask">
      {rows?.map((row, index) => {
        return (
          <tr key={row.id} data-testid="placeholder-row" className={index % 2 ? 'odd' : 'even'}>
            {row.getVisibleCells().map(cell => {
              return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

// Utility function to check the column ordering against an explicit list of sortable columns. And to convert the
// orderBy object into the right format for the get_X_rows API endpoints.
export function getAllowedOrdering<T extends string>(
  orderBy: FetchDataOptions['orderBy'],
  sortableColumns: readonly T[],
  defaultSortColumn: T,
  defaultSortOrder: 'desc' | 'asc' = 'desc',
): string {
  if (orderBy && sortableColumns.includes(orderBy!.id as T)) {
    return `${orderBy.id}-${orderBy.desc ? 'desc' : 'asc'}`;
  } else {
    if (orderBy) {
      console.warn('Cannot sort by non-sortable column "%s", defaulting to "%s"', orderBy.id, defaultSortColumn);
    }
    return `${defaultSortColumn}-${defaultSortOrder}`;
  }
}
