import {Polygon} from 'geojson';
import {DeepNullable} from 'ts-essentials';
import {LngLat} from '../geo';
import {FarmCustomColumns, HarvestCustomColumns} from '../models/CustomColumns';
import {FarmData, HarvestData} from '../models/data';
import {Farm, HarvestYear, Policy} from '../models/interfaces';
import {AreaValue, Loss, UnitPriceValue, YieldValue} from '../models/types';

export class ImportedData {
  farms: ImportedFarm[];

  constructor(data: null | ImportedData) {
    this.farms = data ? data.farms.map(farm => new ImportedFarm(farm)) : [];
  }
}

export class ImportedFarm implements DeepNullable<FarmData> {
  // farm_id == null means that this farm will be inserted.
  // farm_id != null means that the farm will be *updated* with any non-null columns, and any fields will be inserted
  //  to that farm.
  farm_id: null | string;
  external_farm_id: null | string;
  telepac_id: null | string;
  farm_name: null | string;
  farmer_email: string | null;
  farmer_name: string | null;
  address: null | string;
  farm_location: null | LngLat;
  // Note that if we are importing a claim / visit for this farm, then farmHarvests.losses will be assumed to be the
  // complete set of losses for that claim. If we dedupe it, then we will delete any losses that do not appear there.
  // If we are importing a farm without a claim, then farmHarvests.losses will be ignored.
  farmHarvests: ImportedHarvest[];
  fields: ImportedField[];
  claim: null | ImportedClaim;
  editors: string[];
  policy_number: null | string; // A policy corresponding to all added fields' harvests.
  policy_comments: null | string; // Policy comments (e.g. contract type description)
  policy_id: null | string;
  user_group: null | string;
  comments: null | string;
  metadata: null | any;
  custom_columns: FarmCustomColumns;
  attachments: Farm['attachments'];
  merged_ids: null;

  constructor(farm: null | Partial<ImportedFarm>) {
    this.farm_id = farm?.farm_id ?? null;
    this.external_farm_id = farm?.external_farm_id ?? null;
    this.telepac_id = farm?.telepac_id ?? null;
    this.farm_name = farm?.farm_name ?? null;
    this.farmer_email = farm?.farmer_email ?? null;
    this.farmer_name = farm?.farmer_name ?? null;
    this.address = farm?.address ?? null;
    this.farm_location = farm?.farm_location ?? null;
    this.farmHarvests = farm?.farmHarvests?.map(harvest => new ImportedHarvest(harvest)) ?? [];
    this.fields = farm?.fields?.map(field => new ImportedField(field)) ?? [];
    this.claim = farm?.claim ?? null;
    this.policy_number = farm?.policy_number ?? null;
    this.policy_comments = farm?.policy_comments ?? null;
    this.policy_id = farm?.policy_id ?? null;
    this.user_group = farm?.user_group ?? null;
    this.editors = farm?.editors ?? [];
    this.comments = farm?.comments ?? null;
    this.metadata = farm?.metadata ?? null;
    this.custom_columns = new FarmCustomColumns(farm?.custom_columns);
    this.attachments = farm?.attachments ?? [];
    this.merged_ids = null;
  }
}

export function getImportedFarmData(farm: ImportedFarm): FarmData {
  return {
    address: farm.address,
    comments: farm.comments,
    editors: farm.editors,
    external_farm_id: farm.external_farm_id,
    telepac_id: farm.telepac_id,
    farm_location: farm.farm_location,
    farm_name: farm.farm_name,
    farmer_email: farm.farmer_email,
    farmer_name: farm.farmer_name,
    metadata: farm.metadata,
    custom_columns: farm.custom_columns,
    user_group: farm.user_group ?? '',
    attachments: farm.attachments,
    merged_ids: null,
  };
}

export class ImportedField {
  // field_id == null means that this field will be inserted.
  // field_id != null means that the field will be *updated* with any non-null columns, and any harvests will be inserted
  //  to that field.
  field_id: null | string;

  external_field_id: null | string;
  field_shape: null | Polygon;
  field_location: null | LngLat;
  field_area: null | AreaValue;
  harvests: ImportedHarvest[];

  constructor(field: null | Partial<ImportedField>) {
    this.field_id = field?.field_id ?? null;
    this.external_field_id = field?.external_field_id ?? null;
    this.field_shape = field?.field_shape ?? null;
    this.field_location = field?.field_location ?? null;
    this.field_area = field?.field_area ?? null;
    this.harvests = field?.harvests?.map(x => new ImportedHarvest(x)) ?? [];
  }
}

export class ImportedHarvest implements DeepNullable<Omit<HarvestData, 'farm_id' | 'field_id' | 'policy_id'>> {
  // harvest_id == null means that this harvest will be inserted.
  // harvest_id != null means that the harvest will be *updated* with any non-null columns.
  harvest_id?: null | string;
  crop_id: null | string;
  harvest_year: null | HarvestYear;
  organic: boolean | null;
  irrigated: boolean | null;
  harvest_area: null | AreaValue;
  comments: null | string;
  insured_yield: null | YieldValue;
  insured_price: null | UnitPriceValue;
  insured_area: null | AreaValue;
  variety: null | string;
  metadata: null | any;
  custom_columns: HarvestCustomColumns;
  external_harvest_id: null | string;
  losses: Loss[];
  merged_ids: null;
  premium_rate_percent: null;
  commodity_price: null;
  reference_yield: null;
  insured_percent: null;

  constructor(harvest: null | Partial<ImportedHarvest>) {
    this.harvest_id = harvest?.harvest_id ?? null;
    this.crop_id = harvest?.crop_id ?? null;
    this.variety = harvest?.variety ?? null;
    this.harvest_year = harvest?.harvest_year ?? null;
    this.organic = harvest?.organic ?? null;
    this.irrigated = harvest?.irrigated ?? null;
    this.insured_yield = harvest?.insured_yield ?? null;
    this.insured_price = harvest?.insured_price ?? null;
    this.insured_area = harvest?.insured_area ?? null;
    this.harvest_area = harvest?.harvest_area ?? null;
    this.comments = harvest?.comments ?? null;
    this.metadata = harvest?.metadata ?? null;
    this.custom_columns = new HarvestCustomColumns(harvest?.custom_columns);
    this.external_harvest_id = harvest?.external_harvest_id ?? null;
    this.losses = harvest?.losses ?? [];
    this.merged_ids = null;
    this.premium_rate_percent = null;
    this.commodity_price = null;
    this.reference_yield = null;
    this.insured_percent = null;
  }
}

export function getImportedHarvestData(
  farm_id: string,
  field_id: null | string,
  harvest: ImportedHarvest,
): HarvestData {
  return {
    field_id,
    farm_id: field_id ? null : farm_id,
    policy_id: null,
    harvest_year: harvest.harvest_year,
    harvest_area: harvest.harvest_area,
    crop_id: harvest.crop_id,
    variety: harvest.variety,
    organic: harvest.organic,
    irrigated: harvest.irrigated,
    insured_area: harvest.insured_area,
    insured_yield: harvest.insured_yield,
    insured_price: harvest.insured_price,
    comments: harvest.comments,
    custom_columns: harvest.custom_columns,
    metadata: harvest.metadata,
    external_harvest_id: harvest.external_harvest_id,
    merged_ids: null,
    premium_rate_percent: null,
    commodity_price: null,
    reference_yield: null,
    insured_percent: null,
  };
}

export class ImportedClaim {
  claim_id: string | null;
  claim_number: string | null;
  visit_id: string | null;
  assigned_to: string[];
  policy_id: null | string;
  policy_number: string | null;
  metadata: null | any;
  // TODO(seb): Consider making visit creation optional by omitting external_visit_id
  external_visit_id: string | null;
  custom_columns: object | null;
  harvest_year: HarvestYear;

  constructor(claim: Partial<ImportedClaim> & {harvest_year: HarvestYear}) {
    this.claim_id = claim.claim_id ?? null;
    this.claim_number = claim.claim_number ?? null;
    this.visit_id = claim.visit_id ?? null;
    this.assigned_to = claim.assigned_to ?? [];
    this.policy_id = claim.policy_id ?? null;
    this.policy_number = claim.policy_number ?? null;
    this.external_visit_id = claim.external_visit_id ?? null;
    this.metadata = claim.metadata ?? null;
    this.custom_columns = claim.custom_columns ?? null;
    this.harvest_year = claim.harvest_year;
  }
}

export function getMatchingPolicies(
  transitiveGrantors: {[group: string]: undefined | Set<string>},
  policies: Policy[],
  farmUserGroup: string,
  policy_number: string,
): Policy[] {
  const transGroups: undefined | Set<string> = transitiveGrantors[farmUserGroup];
  return policies.filter(x => x.policy_number === policy_number && transGroups?.has(x.user_group));
}
