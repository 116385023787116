import {History} from 'history';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {FormyI} from '../../../src/Formy';
import {Formy} from '../../../src/Formy/Formy';
import {getI18nOptions} from '../../../src/Formy/FormyEnum';
import {HarvestData} from '../../../src/models/data';
import {Farm, Field, HarvestYear, Policy} from '../../../src/models/interfaces';
import {insertHarvest} from '../../../src/redux/actions/db';
import {IndexedCrops} from '../../../src/redux/reducers/crops';
import {getCrops} from '../../../src/selectors/crops';
import {getSelectableHarvestYears} from '../../../src/selectors/harvest';
import {getCountryCodeGroups} from '../../../src/selectors/units';
import {farmDesc, fieldDesc} from '../../../src/text/desc';
import cmp from '../../../src/util/cmp';
import {fetchEntities} from '../../../src/util/fetchEntity';
import {useAsyncMemo} from '../../../src/util/hooks';
import FormyCropSelector from '../Formy/FormyCropSelector';
import {FormyEnum} from '../Formy/FormyEnum';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormySuggest} from '../Formy/FormySuggest';
import {Apis} from '../apis/Apis';
import {useApis} from '../apis/ApisContext';
import SpinningDots from '../components/SpinningDots';
import {validateImportedHarvest} from '../import/validators';
import {reportErr} from '../util/err';
import {HarvestForm} from './EditFields';
import {commitMutations, insertMissingPolicy} from './commit';

async function init(apis: Apis, history: History, field_ids: string[], crops: IndexedCrops) {
  const {t, store, authedFetcher} = apis;
  const fields = await fetchEntities(authedFetcher, 'field', field_ids);
  const farmIds = new Set(fields?.map(x => x.farm_id));
  const farms = await fetchEntities(authedFetcher, 'farm', Array.from(farmIds));

  async function onSubmit(values: HarvestForm) {
    try {
      for (const field_id of field_ids) {
        const field = fields?.find(x => x.field_id == field_id);
        const farm = farms?.find(x => x.farm_id == field?.farm_id);
        if (!field || !farm) {
          reportErr(new Error(`AddHarvests.onSubmit could not find farm/field for: ${field_id}`));
          return;
        }

        insertMissingPolicy(apis, field_id, values, farm?.user_group);
        const data: HarvestData = {
          field_id,
          farm_id: null,
          policy_id: values.policy_id,
          insured_yield: null,
          insured_price: null,
          insured_area: null,
          irrigated: null,
          crop_id: values.crop_id,
          harvest_year: values.harvest_year,
          harvest_area: values.harvest_area,
          variety: values.variety,
          organic: null,
          comments: null,
          metadata: null,
          custom_columns: null,
          external_harvest_id: null,
          merged_ids: null,
          premium_rate_percent: null,
          commodity_price: null,
          reference_yield: null,
          insured_percent: null,
        };
        store.dispatch(insertHarvest(apis, data));
      }
      if (await commitMutations(apis)) {
        history.goBack();
      }
    } catch (e) {
      reportErr(e, 'EditFields::onSubmit');
    }
  }

  const formy = new Formy('new', new HarvestForm(null), t, onSubmit, x => validateImportedHarvest(crops, x));
  return {formy, farms, fields};
}

export default function AddHarvests() {
  const history = useHistory(),
    location = useLocation();
  const apis = useApis(),
    {t, clock} = apis;
  const field_ids = useMemo(
    () => new URLSearchParams(location.search).get('field_ids')?.split(',') ?? [],
    [location.search],
  );
  const key = field_ids.join(',');
  const crops = useSelector(getCrops),
    countryGroups = useSelector(getCountryCodeGroups);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const content = useAsyncMemo(() => init(apis, history, field_ids, crops), [key]);
  const onPolicyDupeSelected = useCallback(
    (x: Policy) => content?.formy.getChangeHandler('policy_id')(x.policy_id),
    [content?.formy],
  );
  if (!content) {
    return <SpinningDots size={75} />;
  }

  const {farms, fields, formy} = content;

  function renderSelectedFields() {
    if (!farms || !fields) {
      return null;
    }
    const entities: [Farm, Field][] = fields?.map(field => {
      const farm = farms.find(x => x.farm_id == field.farm_id);
      if (!farm) {
        throw new Error(`Could not find farm ${field.farm_id} for field ${field.field_id}`);
      }
      return [farm, field];
    });
    entities.sort((a, b) => cmp(a[0].farm_id, b[0].farm_id));
    const farmList = [];
    let curFarm: null | Farm = null,
      curFieldList: Field[] = [];
    for (const entity of entities) {
      if (curFarm && curFarm?.farm_id != entity[0].farm_id) {
        farmList.push(
          <li key={curFarm.farm_id}>
            {farmDesc(t, curFarm)}
            <ul>
              {curFieldList.map(f => (
                <li key={f.field_id}>{fieldDesc(t, crops, countryGroups, f)}</li>
              ))}
            </ul>
          </li>,
        );

        curFieldList = [];
      }
      curFarm = entity[0];

      curFieldList.push(entity[1]);
    }
    if (curFarm) {
      farmList.push(
        <li key={curFarm.farm_id}>
          {farmDesc(t, curFarm)}
          <ul>
            {curFieldList.map(f => (
              <li key={f.field_id}>{fieldDesc(t, crops, countryGroups, f)}</li>
            ))}
          </ul>
        </li>,
      );
    }

    return farmList;
  }

  return (
    <span key={key /* If formy changes, then reset all components. */}>
      <span className="import-form">
        <h1>{t('AddNewHarvestFor')}:</h1>
        <ul>{renderSelectedFields()}</ul>
        <span className="import-row">
          <span className="import-cell harvest-cell">
            <FormyCropSelector formy={formy} field="crop_id" />
          </span>
          <span className="import-cell harvest-cell">
            <FormyEnum<HarvestYear, HarvestForm, 'harvest_year'>
              selectMsg="SelectYear"
              field="harvest_year"
              formy={formy}
              options={getI18nOptions(t, getSelectableHarvestYears(clock))}
            />
          </span>
          <span className="import-cell">
            <FormySuggest<HarvestForm, 'policy_number'>
              onEntitySelected={onPolicyDupeSelected}
              label="PolicyNumber"
              field="policy_number"
              formy={formy as FormyI<HarvestForm>}
            />
          </span>
        </span>
      </span>
      <span className="import-save">
        <FormySubmit label="Save" formy={formy} doNotConfirmSubmissionToUser={true} />
      </span>
    </span>
  );
}
