import {CheckCircleOutlined, ImportOutlined, LoadingOutlined, PlusOutlined, WarningOutlined} from '@ant-design/icons';
import {Badge, Button, Col, PageHeader, Row, Table, type TableColumnsType, Tabs} from 'antd';
import {parse} from 'csv-parse/browser/esm/sync';
import React, {FC, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Locales} from '../../../src/i18n/i18n';
import {UserGroupMembership, grantUserAccess, revokeUserAccess} from '../../../src/models/interfaces';
import {KeycloakPublicServerApiUser} from '../../../src/models/keycloak';
import {getCurUserEmail} from '../../../src/selectors/dbMeta';
import {simplify} from '../../../src/util/cmp';
import {useApis} from '../apis/ApisContext';
import {RemoveKeycloakUser} from '../components/RemoveKeycloakUser';
import SpinningDots from '../components/SpinningDots';
import {UserEntityUpdate} from '../components/UserEntityUpdates';
import {ErrorBoundary} from '../util/ErrorBoundary';
import {
  AdminUserEntity,
  MembershipUpdate,
  MembershipUpdates,
  notACountryGroup,
  useAdminInfo,
  useFetchAll,
} from '../util/admin-util';
import {useKeycloak} from '../util/useKeycloak';
import './UserAdminPage.css';

const BulkImportPage: React.FC = () => {
  const {t} = useApis();
  return (
    <div className="admin-page">
      <Row>
        <Col span={24}>
          <PageHeader
            title={t('UserAdministration')}
            subTitle={t('ManageUserAccountAndSettings')}
            className="no-print" //Hide header when printing
            avatar={{icon: <ImportOutlined />}}
          />
        </Col>
      </Row>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <ErrorBoundary>
            <BulkUserImport />
          </ErrorBoundary>
        </Col>
      </Row>
    </div>
  );
};

export default () => {
  const admin = useAdminInfo();
  const userEmail = useSelector(getCurUserEmail);
  const isGtUser = userEmail.endsWith('@green-triangle.com');
  if (!admin.isUserAdmin || !isGtUser) {
    return null;
  }
  return <BulkImportPage />;
};

const OutputColumns = ['email', 'first_name', 'last_name', 'membership_type', 'user_group'] as const;
type OutputColumn = (typeof OutputColumns)[number];

// Try to auto-map the input columns to the output columns.
function automap(inputColumns: string[]): Partial<Record<OutputColumn, string>> {
  const mapping: Partial<Record<OutputColumn, string>> = {};
  inputColumns.forEach(inputColumn => {
    const simple = simplify(inputColumn);
    if (/mail/i.test(simple)) {
      mapping.email = inputColumn;
      return;
    }
    if (/pre|first/i.test(simple)) {
      mapping.first_name = inputColumn;
      return;
    }
    if (/nom|last/i.test(simple)) {
      mapping.last_name = inputColumn;
      return;
    }
    if (/portfolio|group/i.test(simple)) {
      mapping.user_group = inputColumn;
      return;
    }
    if (/type|membership/i.test(simple)) {
      mapping.membership_type = inputColumn;
      return;
    }
  });
  return mapping;
}

type BulkImportTable = {
  email: string;
  first_name?: string;
  last_name?: string;
  membership_type?: 'full' | 'group-allocator';
  user_group?: string;
  entity?: AdminUserEntity;
  user_entity_exists: boolean;
  memberships_are_up_to_date: boolean;
  updates: MembershipUpdates;
};

export const BulkUserImport: React.FC = () => {
  const {t} = useApis();
  const [file, setFile] = useState<string>();
  const [delimiter, setDelimiter] = useState<string>(',');
  const [columnMap, setColumMap] = useState<Partial<Record<OutputColumn, string>>>({});
  const [overwrites, setOverwrites] = useState<Record<string, BulkImportTable>>({});
  const [locale, setLocale] = useState<KeycloakPublicServerApiUser['locale']>('en');
  const [customer, setCustomer] = useState<string[]>([]);
  const {
    entities: userGroupsMemberships,
    loading: loadingGroupMemberships,
    refetch: refetchMemberships,
  } = useFetchAll('user_group_membership', ['email', 'user_group', 'membership_type']);
  const {
    entities: userEntities,
    loading: loadingUserEntities,
    refetch: refetchEntities,
  } = useFetchAll('user_entity', ['user_id']);
  const {loading: loadingCustomers, transitiveUserAdminGroups} = useAdminInfo();

  const customerOptions: [string, string][] = useMemo(() => {
    return loadingCustomers
      ? []
      : (transitiveUserAdminGroups
          // Filter out the country groups, careful, there are legitimate non-country three letter groups as well.
          .filter(notACountryGroup)
          .map(x => [x.user_group, x.name ? `${x.name} (${x.user_group})` : x.user_group])
          .sort((a, b) => a[1].localeCompare(b[1])) as [string, string][]);
  }, [loadingCustomers, transitiveUserAdminGroups]);

  // Parse the csv input
  const table: Record<string, string>[] = useMemo(() => {
    if (!file) {
      return [];
    }

    try {
      const records: {}[] = parse(file, {
        delimiter,
        columns: true,
        skip_empty_lines: true,
      });
      const inputColumns = records?.length > 0 ? Object.keys(records[0]) : [];
      setColumMap(automap(inputColumns));

      return records as Record<string, string>[];
    } catch (e) {
      console.error('Error parsing csv', e);
      return [];
    }
  }, [delimiter, file]);

  // Bring together all the data
  const {inserts, updates, deletes, nop} = useMemo(() => {
    const data = {
      inserts: [] as BulkImportTable[],
      updates: [] as BulkImportTable[],
      deletes: [] as BulkImportTable[],
      nop: [] as BulkImportTable[],
    };
    if (loadingUserEntities || loadingCustomers) {
      return data;
    }
    const insertsAndUpdates = table
      .map(row => {
        const newRow: BulkImportTable = {
          email: row.toString(),
          membership_type: 'full',
          user_entity_exists: false,
          memberships_are_up_to_date: false,
          updates: {grant: [], revoke: []},
        };
        for (const [outputColumn, inputColumn] of Object.entries(columnMap)) {
          if (outputColumn === 'membership_type') {
            switch (row[inputColumn]?.toLowerCase()) {
              case 'full':
              case 'group-allocator':
                newRow.membership_type = row[inputColumn].toLowerCase() as 'full' | 'group-allocator';
                break;
            }
          } else {
            // @ts-ignore
            newRow[outputColumn as OutputColumn] = row[inputColumn];
          }
        }
        const overwrite: BulkImportTable | undefined = newRow.email ? overwrites[newRow.email] : undefined;
        if (overwrite) {
          Object.assign(newRow, overwrite);
        }
        const entity = newRow.email ? userEntities.find(entity => entity.email === newRow.email) : undefined;
        if (
          newRow.user_group &&
          newRow.membership_type &&
          transitiveUserAdminGroups.find(g => g.user_group === newRow.user_group)
        ) {
          if (entity) {
            newRow.entity = {
              email: entity.email,
              first_name: entity.first_name,
              last_name: entity.last_name,
              customers: entity.customers?.map(c => transitiveUserAdminGroups.find(g => g.user_group === c)!),
              user_id: entity.user_id,
              key: entity.user_id,
              selectable: true,
              memberships: userGroupsMemberships.filter(m => m.email === entity.email).map(resolveUserGroup),
              accessible_user_groups: transitiveUserAdminGroups.filter(g => entity.customers?.includes(g.user_group)),
            } as AdminUserEntity;
            // TODO(seb): This is strictly speaking not correct, as we don't check for transitive customers, but then
            //  transitive customers shouldn't exist in the first place, with the exception of admin (maybe).
            // If the existing user entity does not have the required customer, we need to add it
            newRow.user_entity_exists =
              entity.customers?.includes('admin') || customer.every(c => entity.customers?.includes(c));
            const actualMemberships = userGroupsMemberships.filter(m => m.email === entity.email);
            const expectedMemberships: UserGroupMembership[] = [
              {
                email: entity.email!,
                user_group: newRow.user_group!,
                membership_type: newRow.membership_type!,
              },
            ];
            newRow.updates = {
              grant: expectedMemberships
                .filter(expected => !actualMemberships.some(actual => actual.user_group === expected.user_group))
                .map(resolveUserGroup),
              revoke: actualMemberships
                .filter(actual => !expectedMemberships.some(expected => expected.user_group === actual.user_group))
                .map(resolveUserGroup),
            };
          } else {
            newRow.updates = {
              grant: [
                {
                  email: newRow.email,
                  user_group: newRow.user_group!,
                  membership_type: newRow.membership_type!,
                },
              ].map(resolveUserGroup),
              revoke: [],
            };
          }
          newRow.memberships_are_up_to_date = newRow.updates.grant.length === 0 && newRow.updates.revoke.length === 0;
        }
        return newRow;
      })
      .sort((a, b) => (a?.email && b?.email ? a.email.localeCompare(b.email) : 0));

    data.inserts = insertsAndUpdates.filter(r => !r.user_entity_exists);
    data.updates = insertsAndUpdates.filter(r => r.user_entity_exists && !r.memberships_are_up_to_date);
    data.nop = insertsAndUpdates.filter(r => r.user_entity_exists && r.memberships_are_up_to_date);
    if (customer.length > 0) {
      console.info(
        '__userEntities',
        userEntities.map(u => u.email),
      );
      console.info(
        '__csv',
        insertsAndUpdates.map(u => u.email),
      );
      data.deletes = userEntities
        // Delete only users with the currently selected customer(s)
        .filter(u => customer.every(c => u.customers?.includes(c)))
        // Which are NOT present in the supplied csv data
        .filter(u => insertsAndUpdates.every(t => t.email !== u.email))
        .map(u => {
          return {
            email: u.email!,
            user_entity_exists: true,
            memberships_are_up_to_date: false,
            entity: {
              email: u.email,
              first_name: u.first_name,
              last_name: u.last_name,
              customers: u.customers?.map(c => transitiveUserAdminGroups.find(g => g.user_group === c)!),
              user_id: u.user_id,
              key: u.user_id,
              selectable: true,
              memberships: userGroupsMemberships.filter(m => m.email === u.email).map(resolveUserGroup),
              accessible_user_groups: transitiveUserAdminGroups.filter(g => u.customers?.includes(g.user_group)),
            } as AdminUserEntity,
            updates: {
              grant: [],
              revoke: [...userGroupsMemberships.filter(m => m.email === u.email).map(resolveUserGroup)],
            },
          };
        });
    }

    return data;

    function resolveUserGroup(membership: UserGroupMembership): MembershipUpdate {
      return {
        ...membership,
        user_group: transitiveUserAdminGroups.find(g => g.user_group === membership.user_group)!,
      };
    }
  }, [
    loadingUserEntities,
    loadingCustomers,
    table,
    overwrites,
    userEntities,
    columnMap,
    customer,
    userGroupsMemberships,
    transitiveUserAdminGroups,
  ]);

  const baseColumns: TableColumnsType<BulkImportTable> = [
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
      sorter: (a: BulkImportTable, b: BulkImportTable) => a.email?.localeCompare(b.email || '') || 0,
    },
    {
      title: t('UpdateMemberships'),
      key: 'user_group_membership',
      render: (row: BulkImportTable) => {
        return (
          <UserEntityUpdate
            item={{
              email: row.email,
              added: row.updates.grant,
              removed: row.updates.revoke,
            }}
            renderTitle={false}
          />
        );
      },
    },
  ];
  const insertColumns: TableColumnsType<BulkImportTable> = [
    {
      title: t('AddUser'),
      key: 'user_entity_exists',
      render: row => <ExistsColumn user={row} locale={locale} customer={customer} entity={row.entity} />,
    },
    {
      title: t('FirstName'),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a: BulkImportTable, b: BulkImportTable) => a.first_name?.localeCompare(b.first_name || '') || 0,
      render: (name: string, row) => {
        const rowOverwrite = overwrites[row.email];
        return (
          <input
            value={rowOverwrite?.first_name || name}
            onChange={e =>
              setOverwrites(s => ({
                ...s,
                [row.email]: {...rowOverwrite, first_name: e.target.value},
              }))
            }
          />
        );
      },
    },
    {
      title: t('LastName'),
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a: BulkImportTable, b: BulkImportTable) => a.last_name?.localeCompare(b.last_name || '') || 0,
      render: (name: string, row) => {
        const rowOverwrite = overwrites[row.email];
        return (
          <input
            value={rowOverwrite?.last_name || name}
            onChange={e =>
              setOverwrites(s => ({
                ...s,
                [row.email]: {...rowOverwrite, last_name: e.target.value},
              }))
            }
          />
        );
      },
    },
    ...baseColumns,
  ];
  const deleteColumns: TableColumnsType<BulkImportTable> = [
    {
      title: t('UpdateMemberships'),
      key: 'update_memberships',
      render: (row: BulkImportTable) => {
        return (
          <UpdateMemberships
            item={row}
            onDone={() => {
              refetchEntities();
              refetchMemberships();
            }}
          />
        );
      },
    },
    {
      title: t('User'),
      key: 'user_entity_remove',
      render: row => (
        <RemoveKeycloakUser userEntities={[row.entity!]} onCancel={() => null} onDeleted={() => refetchEntities()} />
      ),
    },
    {
      title: t('FirstName'),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a: BulkImportTable, b: BulkImportTable) => a.first_name?.localeCompare(b.first_name || '') || 0,
      render: (name: string, row) => {
        const rowOverwrite = overwrites[row.email];
        return (
          <input
            value={rowOverwrite?.first_name || name}
            onChange={e =>
              setOverwrites(s => ({
                ...s,
                [row.email]: {...rowOverwrite, first_name: e.target.value},
              }))
            }
          />
        );
      },
    },
    {
      title: t('LastName'),
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a: BulkImportTable, b: BulkImportTable) => a.last_name?.localeCompare(b.last_name || '') || 0,
      render: (name: string, row) => {
        const rowOverwrite = overwrites[row.email];
        return (
          <input
            value={rowOverwrite?.last_name || name}
            onChange={e =>
              setOverwrites(s => ({
                ...s,
                [row.email]: {...rowOverwrite, last_name: e.target.value},
              }))
            }
          />
        );
      },
    },
    ...baseColumns,
  ];
  const nopColumns: TableColumnsType<BulkImportTable> = [...baseColumns];
  const updateColumns: TableColumnsType<BulkImportTable> = [
    {
      title: t('UpdateMemberships'),
      key: 'update_memberships',
      render: (row: BulkImportTable) => {
        return (
          <UpdateMemberships
            item={row}
            onDone={() => {
              refetchEntities();
              refetchMemberships();
            }}
          />
        );
      },
    },
    ...baseColumns,
    {
      title: t('Portfolio'),
      dataIndex: 'user_group',
      key: 'user_group',
      sorter: (a: BulkImportTable, b: BulkImportTable) => a.user_group?.localeCompare(b.user_group || '') || 0,
    },
    {
      title: t('membership_type'),
      dataIndex: 'membership_type',
      key: 'membership_type',
      sorter: (a: BulkImportTable, b: BulkImportTable) =>
        a.membership_type?.localeCompare(b.membership_type || '') || 0,
      render: (type: string, row) => {
        const rowOverwrite = overwrites[row.email];
        return (
          <select
            value={rowOverwrite?.membership_type || type}
            onChange={e => {
              return setOverwrites(s => ({
                ...s,
                [row.email]: {...rowOverwrite, membership_type: e.target.value as BulkImportTable['membership_type']},
              }));
            }}>
            <option value="full">full</option>
            <option value="group-allocator">group-allocator</option>
          </select>
        );
      },
    },
  ];

  if (!file) {
    return (
      <div>
        <FileInput onChange={setFile} />
      </div>
    );
  }

  if (loadingUserEntities || loadingGroupMemberships || loadingCustomers) {
    return (
      <div>
        <SpinningDots size={20} />
      </div>
    );
  }

  const inputColumns = table?.length > 0 ? Object.keys(table[0]) : [];
  const showTables = customer.length > 0;

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{flex: 3}}>
          <table>
            <tbody>
              {OutputColumns.map(outputColumn => (
                <tr key={outputColumn}>
                  <td>
                    <select
                      required={true}
                      value={columnMap[outputColumn]}
                      onChange={e =>
                        setColumMap({
                          ...columnMap,
                          [outputColumn]: e.target.value,
                        })
                      }>
                      <option>-</option>
                      {inputColumns.map(inputColumn => (
                        <option key={inputColumn}>{inputColumn}</option>
                      ))}
                    </select>
                  </td>
                  <td style={{padding: '0 1em'}}>{'=>'}</td>
                  <td>
                    <label>{outputColumn}</label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{flex: 1}}>
          <div>
            <label>{t('Delimiter')}</label>
            <select required={true} value={delimiter} onChange={e => setDelimiter(e.target.value)}>
              <option>,</option>
              <option>;</option>
            </select>
          </div>
          <div>
            <label>{t('Locale')}</label>
            <select
              required={true}
              value={locale ?? undefined}
              onChange={e => setLocale(e.target.value as KeycloakPublicServerApiUser['locale'])}>
              {Locales.map(l => (
                <option key={l} value={l}>
                  {l}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>{t('Customer')}</label>
            <select
              value={customer[0] ?? '-'}
              onChange={e => setCustomer(e.target.value === '-' ? [] : [e.target.value])}>
              <option value="-">-</option>
              {customerOptions.map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <hr />
      {!showTables && <div>{t('MustSelectACustomer')}</div>}
      {showTables && (
        <>
          <Button
            onClick={() => {
              refetchEntities();
              refetchMemberships();
            }}>
            {t('Refresh')}
          </Button>
          <hr />
          <Tabs
            items={[
              {
                label: (
                  <span>
                    {t('AddUser')} <Badge count={inserts.length} showZero style={{backgroundColor: '#52c41a'}} />
                  </span>
                ),
                key: 'insert',
                children: <Table<BulkImportTable> columns={insertColumns} dataSource={inserts} rowKey="email" />,
              },
              {
                label: (
                  <span>
                    {t('ChangeUserAccessRights')}{' '}
                    <Badge count={updates.length} showZero style={{backgroundColor: '#52c41a'}} />
                  </span>
                ),
                key: 'update',
                children: <Table<BulkImportTable> columns={updateColumns} dataSource={updates} rowKey="email" />,
              },
              {
                label: (
                  <span>
                    {t('User')} <Badge count={nop.length} showZero style={{backgroundColor: '#52c41a'}} />
                  </span>
                ),
                key: 'nope',
                children: <Table<BulkImportTable> columns={nopColumns} dataSource={nop} rowKey="email" />,
              },
              {
                label: (
                  <span>
                    {t('DeleteUsers')} <Badge count={deletes.length} showZero style={{backgroundColor: '#52c41a'}} />
                  </span>
                ),
                key: 'delete',
                children: <Table<BulkImportTable> columns={deleteColumns} dataSource={deletes} rowKey="email" />,
              },
            ]}></Tabs>
        </>
      )}
    </div>
  );
};

const FileInput: React.FC<{onChange: (fileContents: string) => void}> = ({onChange}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = e => {
      onChange(e.target?.result as string);
    };
    reader.readAsText(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} role="button" />
    </div>
  );
};

type KeycloakEntityState = true | {message: string} | undefined | 'loading';

function getKeycloakEntityStateIcon(state: KeycloakEntityState) {
  switch (state) {
    case 'loading':
      return <LoadingOutlined />;
    case undefined:
      return <PlusOutlined />;
    case true:
      return <CheckCircleOutlined />;
    default:
      return <WarningOutlined />;
  }
}

const UpdateMemberships: FC<{item: BulkImportTable; onDone: (email: string) => void}> = ({item, onDone}) => {
  const {authedFetcher, t} = useApis();
  const updates = item.updates;
  const onClick = useCallback(async () => {
    try {
      // Postgres has an issue with concurrent updates on data.memberships.
      // One workaround is to serialize the requests.
      for (const u of updates.revoke) {
        await revokeUserAccess(authedFetcher, {
          param_email: u.email,
          param_user_group: u.user_group.user_group,
          param_membership_type: u.membership_type,
        });
      }
      for (const u of updates.grant) {
        await grantUserAccess(authedFetcher, {
          param_email: u.email,
          param_user_group: u.user_group.user_group,
          param_membership_type: u.membership_type,
        });
      }
      onDone(item.email);
    } finally {
    }
  }, [authedFetcher, item.email, onDone, updates.grant, updates.revoke]);
  return (
    <Button type="primary" disabled={updates.grant.length === 0 && updates.revoke.length === 0} onClick={onClick}>
      {t('UpdateMemberships')}
    </Button>
  );
};

const ExistsColumn: React.FC<{
  user: BulkImportTable;
  locale: KeycloakPublicServerApiUser['locale'];
  customer: string[];
  entity?: AdminUserEntity;
}> = ({user, locale, customer, entity}) => {
  const {t, authedFetcher} = useApis();
  const [loadingState, setLoadingState] = useState<KeycloakEntityState>(undefined);
  const {add} = useKeycloak();
  return user.user_entity_exists || loadingState === true ? (
    <div>
      <CheckCircleOutlined /> Exists
      <div>
        {t('Customer')}: {entity?.customers?.join(', ')}
      </div>
    </div>
  ) : (
    <div>
      <Button
        type="primary"
        disabled={customer.length === 0 || loadingState === 'loading'}
        icon={getKeycloakEntityStateIcon(loadingState)}
        onClick={async () => {
          setLoadingState('loading');
          try {
            // First create the user entity
            const newUserEntity = await add({
              email: user.email,
              firstName: user.first_name ?? null,
              lastName: user.last_name ?? null,
              locale: locale,
              customer,
            });
            if (newUserEntity === true) {
              // Then add any needed memberships
              for (const m of user.updates.grant) {
                await grantUserAccess(authedFetcher, {
                  param_email: user.email,
                  param_user_group: m.user_group.user_group,
                  param_membership_type: m.membership_type,
                });
              }
            }
            setLoadingState(newUserEntity);
          } catch (e) {
            setLoadingState(e as Error);
          }
        }}>
        {t('AddUser')}
      </Button>
      {entity && (
        <div>
          {t('Customer')}: {entity?.customers?.join(', ')}
        </div>
      )}
    </div>
  );
};
