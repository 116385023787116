import {ErrorBoundary} from '@sentry/react';
import {createColumnHelper} from '@tanstack/react-table';
import {Button, Checkbox, Space, Tooltip} from 'antd';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {getEnabledFlags} from '../../../src/feature-flags';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {
  Farm,
  GetClaimRowsRequest,
  GetClaimRowsRow,
  Policy,
  VisitCandidate,
  getClaimRows,
} from '../../../src/models/interfaces';
import {formatDate} from '../../../src/text/date';
import {farmDesc, policyDesc} from '../../../src/text/desc';
import {remove} from '../../../src/util/arr-util';
import {filtersToRequest} from '../../../src/util/req-util';
import {useApis} from '../apis/ApisContext';
import {InfinityTable, InfinityTableProps, defaultColumnSizes} from '../components/InfinityTable';
import {UserEntityInfo} from '../components/UserEntityInfo';
import './Claims.css';

const columnHelper = createColumnHelper<GetClaimRowsRow>();
const getClaimId = (row: GetClaimRowsRow): string | null => row.claim?.external_claim_id ?? null;
const getUpdatedAt = (row: GetClaimRowsRow): string | null => row.claim?.updated_at ?? null;
const getFarm = (row: GetClaimRowsRow): Farm | null => row.farm;
const getPolicy = (row: GetClaimRowsRow): Policy | null => row.policy;
const getAssignedTo = (row: GetClaimRowsRow): VisitCandidate[] => row.claim?.assigned_to?.filter(remove.nulls) ?? [];
const getStatus = (row: GetClaimRowsRow): string | null => row.claim?.status ?? null;
const sortValue = (row: GetClaimRowsRow): string | null => row.claim?.added_on ?? null;
const rowId = (row: GetClaimRowsRow): string => row.claim?.claim_id ?? '';

export const Claims: React.FC = () => {
  const {t, authedFetcher} = useApis();
  const [selectedClaims, setSelectedClaims] = React.useState<GetClaimRowsRow[]>([]);
  const flags = useSelector(getEnabledFlags);
  const fetchDataFromApi: InfinityTableProps<GetClaimRowsRow>['fetchData'] = useCallback(
    async ({filters, pageParam}): Promise<GetClaimRowsRow[]> => {
      const req: GetClaimRowsRequest = {
        ...filtersToRequest(filters),
        ordering: 'added_on-desc',
        row_count: 25,
        continue_from_val: pageParam?.continue_from_val ?? null,
        continue_from_id: pageParam?.continue_from_id ?? null,
      };
      return getClaimRows(authedFetcher, req);
    },
    [authedFetcher],
  );
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: '__selection__',
        cell: ({row}) =>
          row.getCanSelect() && <Checkbox checked={row.getIsSelected()} onChange={() => row.toggleSelected()} />,
        size: defaultColumnSizes.xxs,
        header: '',
        enableResizing: false,
        meta: {
          isFixedWidthColumn: true,
        },
      }),
      columnHelper.accessor<typeof getClaimId, ReturnType<typeof getClaimId>>(getClaimId, {
        id: 'external_claim_id',
        header: t('ExternalClaimId'),
        size: defaultColumnSizes.s,
        cell: ({getValue}) => <span className="mask">{getValue()}</span>,
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getFarm, ReturnType<typeof getFarm>>(getFarm, {
        id: 'farm_id',
        header: t('Farm'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => {
          const farm = getValue();
          return <span className="mask">{farm ? farmDesc(t, farm) : ''}</span>;
        },
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getPolicy, ReturnType<typeof getPolicy>>(getPolicy, {
        id: 'policy_id',
        header: t('Policy'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => {
          const policy = getValue();
          return <span className="mask">{policy ? policyDesc(t, policy) : ''}</span>;
        },
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getAssignedTo, ReturnType<typeof getAssignedTo>>(getAssignedTo, {
        id: 'assigned_to',
        header: t('AssignedTo'),
        size: defaultColumnSizes.l,
        cell: ({getValue}) => {
          return (
            <span className="mask">
              {getValue().map((assigned, idx) => (
                <React.Fragment key={'assigned-' + idx}>
                  <UserEntityInfo email={assigned.email} />
                  <span>{' (' + assigned!.visit_candidate_status + ')'}</span>
                </React.Fragment>
              ))}
            </span>
          );
        },
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getStatus, ReturnType<typeof getStatus>>(getStatus, {
        id: 'status',
        header: t('Status'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => {
          let value = getValue();
          return <span className="mask">{value ? t(('ClaimStatus_' + value) as I18nSimpleKey) : ''}</span>;
        },
        enableSorting: false,
      }),
      columnHelper.accessor<typeof getUpdatedAt, ReturnType<typeof getUpdatedAt>>(getUpdatedAt, {
        id: 'updated_at',
        header: t('LastChanged'),
        size: defaultColumnSizes.m,
        cell: ({getValue}) => {
          const date = getValue();
          return <span className="mask">{date ? formatDate(t, date) : ''} </span>;
        },
        enableSorting: false,
      }),
    ],
    [t],
  );

  return (
    <div>
      <div className="list-view-action-buttons">
        <Space>
          {!flags.has('hideEditingFunctionality') && (
            <Link to={'/add/claim'}>
              <Button>{t('AddClaim')}</Button>
            </Link>
          )}
          {flags.has('hideEditingFunctionality') ? null : !selectedClaims.length || selectedClaims.length > 1 ? (
            <Tooltip title={t('PleaseSelectClaim')}>
              <Button disabled>{t('Edit')}</Button>
            </Tooltip>
          ) : (
            <Link to={'/edit/claim?claim_id=' + selectedClaims[0].claim?.claim_id}>
              <Button id="edit">{t('Edit')}</Button>
            </Link>
          )}
        </Space>
      </div>

      <ErrorBoundary>
        <InfinityTable<GetClaimRowsRow>
          columns={columns}
          initialSorting={{id: 'claim_id', desc: true}}
          fetchData={fetchDataFromApi}
          fetchSetSize={10}
          tableId="list/Claim"
          getRowId={rowId}
          getSortValue={sortValue}
          onRowSelectionChange={setSelectedClaims}
          indentDetails={1}
        />
      </ErrorBoundary>
    </div>
  );
};

export default Claims;
