// @formatter:off
import {intl_int, intl_num, intl_num_2, intl_num_4} from './i18n-util';
import {I18nParametric, I18nSimpleKey} from './i18n';

const msgs = {
  '1990': '1990',
  '1991': '1991',
  '1992': '1992',
  '1993': '1993',
  '1994': '1994',
  '1995': '1995',
  '1996': '1996',
  '1997': '1997',
  '1998': '1998',
  '1999': '1999',
  '2000': '2000',
  '2001': '2001',
  '2002': '2002',
  '2003': '2003',
  '2004': '2004',
  '2005': '2005',
  '2006': '2006',
  '2007': '2007',
  '2008': '2008',
  '2009': '2009',
  '2010': '2010',
  '2011': '2011',
  '2012': '2012',
  '2013': '2013',
  '2014': '2014',
  '2015': '2015',
  '2016': '2016',
  '2017': '2017',
  '2018': '2018',
  '2019': '2019',
  '2020': '2020',
  '2021': '2021',
  '2022': '2022',
  '2023': '2023',
  '2024': '2024',
  '2025': '2025',
  '2026': '2026',
  '2027': '2027',
  '2028': '2028',
  '2029': '2029',
  '2030': '2030',
  '2031': '2031',
  '2032': '2032',
  '2033': '2033',
  '2034': '2034',
  '2035': '2035',
  'ARG': 'Argentina',
  'AUS': 'Austrália',
  'AUT': 'Áustria',
  'Accept': 'Aceitar',
  'AcceptOrDecline': 'Aceitar/recusar',
  'AddAnotherArea': 'Adicionar uma zona',
  'AddAssignee': 'Adicionar Assinante',
  'AddAttachments': 'Adicionar Anexos',
  'AddCause': 'Adicionar uma causa',
  'AddClaim': 'Adicionar reivindicação',
  'AddCost': 'Adicionar custo',
  'AddCrop': 'Adicionar tipo de cultura',
  'AddEditSampleShape': 'Desenhe a área afetada no mapa',
  'AddEditor': 'Adicionar Editor',
  'AddEditors': 'Adicionar editores',
  'AddEditorsOneGroup': 'Favor incluir editores para os itens em um portfolio só',
  'AddFarm': 'Adicionar uma fazenda',
  'AddFarmInfo': 'Observe que, para adicionar talhões novos/adicionais a um campo EXISTENTE, é necessário primeiro pesquisá-lo por Nome do campo ou Referência do campo nos talhões correspondentes abaixo.',
  'AddField': 'Adicionar um talhão',
  'AddFieldShape': 'Adicionar perímetro do talhão',
  'AddFieldShapes': 'Adicionar perímetros do talhão',
  'AddFieldsHarvests': 'Adicionar talhões e safra',
  'AddFromFileManager': 'Adicionar do Gerenciador de Arquivos',
  'AddFromPhotos': 'Adicionar de fotos',
  'AddGuaranteedLossCause': 'Adicionar uma causa de perda coberta',
  'AddHarvest': 'Adicione uma safra',
  'AddLossCause': 'Adicionar causa da perda',
  'AddNewDamage': 'Adicionar novo dano',
  'AddNewHarvest': 'Criare uma safra',
  'AddNewHarvestFor': 'Criar uma nova colheita para',
  'AddNewLoss': 'Adicionar nova perda',
  'AddNewSubparcel': 'Adicionar novo subparcela',
  'AddOrEditFields': 'Adicionar/editar campos',
  'AddPhoto': 'Adicionar uma foto',
  'AddPhotoError': 'Problema ao tirar foto',
  'AddPolicy': 'Adicionar uma apólice',
  'AddRecipient': 'Adicionar destinatário',
  'AddSample': 'Adicionar uma amostra',
  'AddSamplePhoto': 'Adicionar uma foto à amostra',
  'AddSubplotId': 'Adicionar ID de subtrama',
  'AddUnguaranteedLossCause': 'Adicionar uma causa de perda não coberta',
  'AddUser': 'Adicionar usuário',
  'AddVarietyHarvest': 'Adicione variedade',
  'AddedBy': 'Adicionado por',
  'AddedGroups': 'Grupos adicionados',
  'AddedOn': 'Adicionado em',
  'AdditionalInfo': 'Informação adicionalis',
  'AdditionalQuestions': 'Questões adicionais',
  'AdvancedOptions': 'Opções avançadas',
  'AffectedArea': 'Área afetada',
  'AffectedCrop': 'Cultura afetada',
  'AffectedCrops': 'Culturas afetadas',
  'AffectedCropsRequiredToDeclareLoss': 'Você deve selecionar pelo menos uma cultura afetada para adicionar as perdas declaradas.',
  'AffectedField': 'Talhão atingido',
  'All': 'Todos',
  'AllSynced': 'Tudo sincronizado',
  'AllTime': 'Todos o tempo',
  'AlsoInheritedFrom': 'Também herdado de',
  'Amount': 'Valor',
  'AppVersionDeprecated': 'Versão do aplicativo obsoleta',
  'AppVersionDeprecatedMessage': 'A versão do seu aplicativo está obsoleta. Você deve atualizar a página para obter a nova versão. Clique em ATUALIZAR para atualizar a página. Se você tiver dados não salvos, clique em cancelar, faça backup manual dos dados e atualize a página.',
  'Area': 'Área',
  'AreaCropTitle': 'Área por cultura',
  'Ascending': 'ascendente',
  'Assign': 'Assinar',
  'AssignExistingFields': 'Alocar campos existentes',
  'AssignedLossAdjusters': 'Ajustadores de perdas atribuídos',
  'AssignedTo': 'Atribuído a',
  'AssociatedEmails': 'E-mails relacionados',
  'Attachment': 'Anexo',
  'Attachments': 'Anexos',
  'Average': 'Média',
  'AverageFieldArea': 'Área média do talhão',
  'AvgBollsPerPlant': 'Número médio de capulhos por planta',
  'AvgEarsPerPlant': 'Número médio de espigas por planta',
  'AvgGrainsPerEar': 'Número médio de grãos por espiga',
  'AvgGrainsPerPod': 'Número médio de grãos por vagem',
  'AvgGrainsPerSilique': 'Número médio de grãos por vagem',
  'AvgKernelsPerBoll': 'Número médio de grãos por capulho',
  'AvgPodsPerShaft': 'Número médio de vainas por galho',
  'AvgSiliquesPerShaft': 'Número médio de vainas por galho',
  'BEL': 'Bélgica',
  'BRA': 'Brasil',
  'Back': 'Retornar',
  'BackToHome': 'Voltar à página principal',
  'Benchmark': 'Referência',
  'Biomass': 'Biomassa',
  'BunchWeight': 'Peso médio por cacho de uvas (gramas)',
  'BunchesPerPlant': 'Número médio de cachos de uvas por planta',
  'ByCrop': 'Por cultivo',
  'ByField': 'Por talhão',
  'ByFieldArea': 'Por área de talhões',
  'ByFieldCount': 'Por contagem de talhões',
  'CAN': 'Canadá',
  'CHE': 'Suíça',
  'CZE': 'Tcheca',
  'Calculate': 'Calcular',
  'CalculateAbbreviated': 'Calc.',
  'Cancel': 'Cancelar',
  'CantSelectTooManyItems': 'Você não pode selecionar mais de 50 itens.',
  'Change': 'Editar',
  'ChangeOverrideSampleMsg': 'Tem certeza de que deseja alterar o valor de substituição deste corte?',
  'ChangeUserAccessRights': 'Alterar direitos de acesso do usuário',
  'CheckForCodepushUpdate': 'Verifique se há atualizações',
  'CirclesArea': 'Área do Círculo',
  'CirclesDiameter': 'Diâmetro do Círculo',
  'CirclesRadius': 'Raio do Círculo',
  'Claim': 'Alegar',
  'ClaimNumber': 'Número de sinistro',
  'ClaimProcessingStartEnd': 'Início e fim do processamento de reivindicações',
  'ClaimRequests': 'Solicitações de reivindicação',
  'ClaimStatus_adjuster-accepted': 'Ajustador aceito',
  'ClaimStatus_assessment-in-progress': 'Avaliação em andamento',
  'ClaimStatus_awaiting-adjuster-acceptance': 'Aguardando aceitação do ajustador',
  'ClaimStatus_completed': 'Concluído',
  'ClaimStatus_created-unassigned': 'Criado e não atribuído',
  'ClaimStatus_payment-proposal-sent': 'Proposta de pagamento enviada',
  'ClaimStatus_rejected': 'Rejeitado',
  'ClaimStatus_visit-date-defined': 'Data da visita definida',
  'ClaimStatus_visit-report-created': 'Relatório de visita criado',
  'ClaimStatus_visit-samples-collected': 'Visita iniciada',
  'Claims': 'Reivindicações',
  'Clear': 'Limpar',
  'ClearAll': 'Apagar tudo',
  'Clearing': 'Apagar',
  'ClickHereToAddFarmerEmail': 'Clique aqui para adicionar o e-mail do agricultor',
  'ClickUpload': 'Transmitir',
  'Close': 'Fechar',
  'CloseVisit': 'Fechar vistoria',
  'Closed': 'Fechado',
  'ClosedVisits': 'Inspeções Fechadas',
  'CloudCover': 'Cobertura de nuvens',
  'CloudCoverInfo': 'Esta coluna destaca se algum ano houve muita nebulosidade para uma medição de produtividade confiável (> 50% das semanas com núvens durante o período de pico)',
  'CloudCoverWarning': 'Alerta de cobertura de nuvens',
  'CommentInfoWarning': 'Aviso! Não aparece nos relatórios.',
  'Comments': 'Comentários',
  'CompassHeading': 'Cabeçalho',
  'CompensableLosses': 'Perdas Indenizáveis',
  'Condition': 'Condição',
  'Configuration': 'Configuração',
  'Confirm': 'Confirmar',
  'Contact': 'Contate-nos',
  'ContainsCopernicus': 'Contém dados do Serviço Copernicus (2017)',
  'ContinueWithErrorsAndWarnings': 'Continuar sem corrigir erros e avisos?',
  'ConversionRatio': 'Taxa de conversão',
  'CopyCurrentHarvest': 'Copiar colheita atual',
  'CopyHarvestFromPreviousYears': 'Copiar colheita de anos anteriores',
  'CopyLink': 'Copiar link',
  'CopySample': 'Copiar amostra',
  'CopyVisit': 'Copiar inspeção',
  'CostType': 'Tipo de Custo',
  'Costs': 'Custos',
  'CouldntDelete': 'Não foi possível eliminar este elemento. Precisa de estar online para eliminar.',
  'CreateNewVisit': 'Criar nova visita',
  'CropCondition': 'Condições da  lavoura',
  'CropConditionBreakdown': 'Estado das culturas',
  'CropCoverage': 'Cobertura de Cultivo',
  'CropCoverageInfo': 'Isto mostra o percentual do talhão coberto pela cultura de interesse',
  'CropType-s': 'Tipo(s) de Cultura',
  'CropVariety': 'Variedade',
  'CropYieldAverageTitle': 'Produtividade médio',
  'CurHarvest': 'Colheita atual',
  'CurrentFilters': 'Filtros atuais',
  'CurrentVisit': 'Inspeção Atual',
  'CurrentVisits': 'Inspeções Atuais',
  'Customer': 'Cliente',
  'DEU': 'Alemanha',
  'DamagedFields': 'Campos danificados',
  'DamagedHarvest': 'Colheita danificada',
  'Damages': 'Danos',
  'Dashboard': 'Painel de controle',
  'Date': 'Data',
  'Date-s': 'Data(s)',
  'DateRange': 'Período',
  'DeadPlantsPct': 'Plantas mortas (%)',
  'DeclaredHazard': 'Evento declarado',
  'DeclaredLossCauses': 'Causas das perdas declaradas',
  'Decline': 'Declínio',
  'Deductible': 'Dedutivel',
  'Deductible-s': 'Franquia(s)',
  'Delete': 'Apagar',
  'DeleteForEveryone': 'Excluir para todos',
  'DeleteOfflineArea': 'Excluir área offline',
  'DeleteOverrideSampleMsg': 'Tem certeza de que deseja excluir a estimativa de substituição? Se sim, recorreremos às amostras para derivar o valor estimado agregado ao nível da cultura.',
  'DeleteUser': 'Deletar usuário',
  'DeleteUsers': 'Excluir usuários',
  'Delimiter': 'Delimitador',
  'Descending': 'descendente',
  'DetachedSample-s': 'Amostra(s) separada(s)',
  'Details': 'Detalhes',
  'DetectDensity': 'Detectar densidade',
  'DisablePoiMode': 'Mostrar todos os campos',
  'Done': 'Feito',
  'Download': 'Baixar',
  'DownloadFailed': 'Falha no download',
  'DownloadGeojson': 'Descarregar ficheiro GeoJSON',
  'DownloadSuccess': 'Baixado com sucesso',
  'DownloadXLSX': 'Baixar arquivo Excel',
  'Downloading': 'Baixando',
  'DownloadingInitialData': 'Baixando dados iniciais',
  'Draw': 'Desenhar',
  'DuplicateEntryWasRemoved': 'A seguinte entrada já existe e não será importada:',
  'ESP': 'Espanha',
  'EarDensity': 'Densidade da espiga',
  'Edit': 'Editar',
  'EditClaim': 'Editar reivindicação',
  'EditFarm': 'Modificar uma fazenda',
  'EditField': 'Editar um talhão',
  'EditFieldShape': 'Edite o perímetro do talhão',
  'EditHarvest': 'Mudar cultura',
  'EditPolicy': 'Editar a Apólice',
  'EditSample': 'Editar amostra',
  'Editors': 'Editores',
  'Email': 'E-mail',
  'EmailMissing': 'Adicione seu e-mail!',
  'Emergence': 'Emergência',
  'EmergenceDate': 'Data de emergência',
  'EnableNow': 'Ativar agora',
  'EnablePoiMode': 'Mostrar apenas campos de interesse',
  'EnablePreciseLocation': 'Por favor, habilite a Localização Precisa',
  'EndDate': 'Data final',
  'EndDateTime': 'Data e hora de término',
  'Entity': 'Entidade',
  'ErrMissingFields': 'Preencha os campos ausentes.',
  'Error': 'Erro',
  'EstimatedLossAbbr': 'Perda est.',
  'EstimatedYield': 'Produtividade estimada',
  'EstimatedYieldAbbr': 'Produtividade est.',
  'EstimatedYieldAreaWeighted': 'Rendimento estimado (área ponderada)',
  'EstimatedYieldArithmeticMean': 'Rendimento estimado (média aritmética)',
  'EstimatedYieldLoss': 'Perda de Produtividade estimada',
  'Estimation': 'Avaliação',
  'EventDate': 'Data do evento',
  'EventValidation': 'Validação de evento',
  'Existing': 'Existente',
  'ExistingClaim': 'Reivindicação Existente',
  'ExistingClaimMsg': 'Existe uma reivindicação existente para esta fazenda. Gostaria de continuar com a reivindicação existente?',
  'ExistingFarm': 'Esta fazenda já existe.',
  'ExoBinUnsupported': 'No momento, este recurso não é compatível com esta versão do aplicativo. Atualize o aplicativo para a versão mais recente.',
  'ExoCamera': 'Câmera Exo',
  'ExoDetectionError': 'Incapaz de detectar ouvidos. Tente novamente ajustando a posição ou capturando uma nova imagem.',
  'ExoModelNotDownloaded': 'Modelo Exo não baixado. Conecte-se à Internet para baixar o modelo para uso offline.',
  'ExoNotEnabledForUser': 'Este recurso não está habilitado para sua empresa ou grupo de usuários. Entre em contato com seu administrador.',
  'ExoTutorialPanDescription': 'Mova a imagem usando um dedo.',
  'ExoTutorialPinchDescription': 'Aperte a imagem para aumentar e diminuir o zoom.',
  'ExoTutorialSearchDescription': 'Alinhe a zona de detecção com a parte pretendida da imagem, até que fique verde.',
  'Expert': 'Especialista',
  'Expert-s': 'Especialista(s)',
  'ExpertName': 'Nome do Perito',
  'ExpertRanking': 'Classificação de especialistas',
  'ExpertReport': 'Relatório do especialista',
  'ExternalClaimId': 'ID de reivindicação externa',
  'ExternalFieldId': 'ID do campo externo',
  'ExtremelyHighTemperature': 'Onda de calor extrema (Tmax>38°C)',
  'ExtremelyLowTemperature': 'Risco extremo de geada (-15°C>Tmin)',
  'FRA': 'França',
  'FailedToSyncChanges': 'Falha ao sincronizar as alterações',
  'Farm': 'Fazenda',
  'FarmAddress': 'Endereço da fazenda',
  'FarmAndHarvest': 'Informação da quinta e da colheita',
  'FarmDeletedPleaseStartOver': 'Fazenda foi deletada! Favor começar de novo',
  'FarmFormComment': 'Informações operacionais adicionais',
  'FarmHarvestLevel': 'Nível-Safra',
  'FarmHarvestLevelObservation': 'Observação da Safra',
  'FarmHarvests': 'Fazenda Safras',
  'FarmInfo': 'Informações da fazenda',
  'FarmLocationPrompt': 'Pressione firmemente no mapa para localizar a fazenda ou pressione a seta azul à esquerda para usar sua localização atual',
  'FarmName': 'Nome da fazenda',
  'FarmReference': 'Referência da fazenda',
  'Farmer': 'Agricultor',
  'FarmerName': 'Nome do Agricultor',
  'FarmerOrRepresentative': 'Agricultor ou representante',
  'FarmerRepresentative': 'Representante do agricultor',
  'FarmersEstimatedYieldLoss': 'Perda de rendimento estimada do agricultor',
  'Farms': 'Fazendas',
  'FeasibleYield': 'Rendimento atingível',
  'FeasibleYieldEmpty': 'O rendimento viável está vazio.',
  'Feedback': 'Comentário',
  'FetchReportOfflineInfo': 'Parece que você está off-line, por isso não é possível obter o relatório.',
  'Field': 'Talhão',
  'Field-s': 'Talhão(s)',
  'FieldArea': 'Área do talhão',
  'FieldAreaAndReference': 'Área do talhãoe de referência',
  'FieldCultivatedArea': 'Área cultivada (campo)',
  'FieldFormComment': 'Informações adicionais do talhão',
  'FieldHarvests': 'Ano Colheita -> talhão',
  'FieldInfo': 'Informações do talhão',
  'FieldLevel': 'Nível-talhão',
  'FieldLocationPrompt': 'Pressione firmemente no mapa para localizar o talhão ou pressione a seta azul à esquerda para usar sua localização atual',
  'FieldManagement': 'Manejo de campo',
  'FieldMissingCurPolicyHarvest': 'Esse talhão não possui apólice ou cultura associado; pressione aqui para combinar essas informações.',
  'FieldReference': 'Referência do talhão',
  'FieldScoring': 'Pontuação do talhão',
  'FieldShapeAndFarm': 'Formato do talhão e da fazenda',
  'Fields': 'Talhão',
  'FileActions': 'Ações de arquivo',
  'FilterList': 'Filtrar a lista',
  'Filters': 'Filtros',
  'FirstAndLastName': 'Primeiro sobrenome',
  'FirstName': 'Primeiro nome',
  'FloodZone': 'Zona de inundação',
  'ForgotPassword': 'Redefinir senha',
  'FormSubmitted': 'Salvado!',
  'FrontPageReportDisclaimer': 'Sujeito à validade das garantias',
  'FungicideApplied': 'Fungicida aplicado',
  'GPSModuleUnavailable': 'Módulo GPS indisponível',
  'Generate': 'Gerar',
  'GenerateReport': 'Gerar relatório',
  'GeneratingReportPleaseWait': 'Gerando reporte, favor aguardar...',
  'GeographicalArea': 'Área Geográfica',
  'GetDirectionsField': 'Obter direções para o campo',
  'GrainMoisture': 'Umidade dos grãos',
  'GrainMoistureReduction': 'Redução devido à umidade dos grãos',
  'GrantsTo': 'Subsídios para',
  'GrapeVariety': 'Uva Variedade',
  'GrossSampleProduction': 'Produção bruta da amostra',
  'GuaranteedLosses': 'Perdas Garantidas',
  'HUN': 'Hungria',
  'HailInfo': 'Esta camada apresenta o risco de ocorrência de granizo.',
  'Harvest': 'Cultura',
  'HarvestArea': 'Area da Cultura',
  'HarvestAreaMustBePositiveAndLessThanFieldArea': 'A área de colheita deve ser positiva e não maior do que a área do talhão!',
  'HarvestFormComment': 'Informações adicionais de colheita',
  'HarvestInfo': 'Informação da cultura',
  'HarvestLoss': 'Perdas de colheita',
  'HarvestModificationDisabledMsg': 'Esta colheita não pode ser modificada, pois foi criada pela seguradora.',
  'HarvestPlotId': 'Sub-talhão ID',
  'HarvestPractice': 'Tipo de cultura',
  'HarvestReference': 'Referência de Colheita',
  'HarvestYear': 'Ano da colheita',
  'Harvester': 'Colheitadeira',
  'HarvesterDistanceHarvested': 'Distância colhida',
  'HarvesterPlatformWidth': 'Largura de colheita da plataforma',
  'Harvests': 'safras',
  'HarvestsWillNotBeUpdated': 'As colheitas destes campos não serão atualizadas.',
  'HelloReportIssue': 'Olá, gostaria de relatar o seguinte problema encontrado com o aplicativo GreenTriangle:',
  'HerbicideApplied': 'Herbicida Aplicado',
  'HereAddAsNew': 'Clique aqui para adicionar como uma nova fazenda.',
  'HideDataFromMap': 'Ocultar dados do mapa',
  'HighHailRisk': 'Risco elevado de danos causados por granizo',
  'HighPWind': 'Tormenta (28.5-32.5 m/s)',
  'HighPrecipitation': 'Precipitação elevada (30-50 mm/semana)',
  'HighSoilMoisture': 'Um pouco mais úmido que o normal',
  'HighTemperature': 'Onda de calor leve (25°C<Tmax<29°C)',
  'HighVegetation': 'Saúde vegetativa ligeiramente acima do normal (+25%)',
  'Higher': 'Superior',
  'HigherSoilMoisture': 'Mais úmido que o normal',
  'HigherTemperature': 'Onda de calor moderada (29°C<Tmax<34°C)',
  'HigherVegetation': 'Saúde vegetativa acima do normal (+40%)',
  'Highest': 'Máximo',
  'HighestSoilMoisture': 'Significativamente mais úmido que o normal',
  'HighestTemperature': 'Onda de calor forte (34°C<Tmax<38°C)',
  'HighestVegetation': 'Saúde vegetativa muito acima do normal (+55%)',
  'HistoricalDeclaredYields': 'Produtividades históricas declaradas',
  'HistoricalObservedYields': 'Produtividades históricas observadas',
  'HistoricalRealizedYields': 'Produtividade histórica',
  'Homogeneity': 'Homogeneidade',
  'HomogeneityInfo': 'A pontuação de homogeneidade mede o quão homogêneo o talhão tem sido historicamente em termos de desenvolvimento da cultura',
  'ID': 'EU IA',
  'ITA': 'Itália',
  'ImportData': 'Importar os dados',
  'Impurities': 'Impurezas',
  'Info': 'Info',
  'InheritedFrom': 'Herdado de',
  'Initials': 'Iniciais',
  'InsecticideApplied': 'Inseticida aplicado',
  'Insee': 'INSEE',
  'Install': 'Instalar',
  'InsuredArea': 'Area Segurada',
  'InsuredCropRequired': 'Selecione pelo menos uma cultura segurada para continuar.',
  'InsuredCrops': 'Culturas Seguradas',
  'InsuredPrice': 'Custo do seguro',
  'InsuredProduction': 'Produção Segurada',
  'InsuredYield': 'Produtividade garantida',
  'InterfieldInfo': 'A camada "comparação de talhões" exibe os valores absolutos do índice de vegetação e permite a comparação direta do estado da vegetação geral entre as diferentes talhões.',
  'InteryieldInfo': 'Estimativa de produtividade apresenta a previsão de rendimiento com base em sensoramento remoto e dados climáticos, disponíveis até a data estabelecida.',
  'IntrafieldInfo': 'A camada de variabilidade intra-talhão exibe os valores normalizados do índice de vegetação para o talhão monitorado. É subdividida em 5 zonas de áreas iguais correspondentes a 20 % (ou o quintil) dos valores do índice, do mais baixo para o mais alto.',
  'InvalidShapeTraced': 'Sua forma não pode ter linhas que se cruzam. Por favor, desenhe uma forma simples sem cruzamentos.',
  'Irrigated': 'Irrigado',
  'IrrigatedQuestionMark': 'Irrigado?',
  'KernelsPerEar': 'Grãos por espiga',
  'KernerlsPerUnit': 'Grãos por unidade',
  'LTU': 'Lituânia',
  'LastChanged': 'Última alteração',
  'LastName': 'Sobrenome',
  'Legend': 'Legenda',
  'LessDetails': 'Menos detalhes',
  'LinkCopiedToClipboard': 'Link copiado ao clipboard',
  'LinkToThisLocation': 'Relacionar a esta localização',
  'List': 'Lista',
  'LiveSampleFeed': 'Amostras ao vivo',
  'Loading': 'Carregando',
  'Locale': 'Linguagem',
  'Location': 'Localização',
  'LocationAndFarm': 'Localização e Fazenda',
  'LocationMocked': 'Parece que tem problemas com a sua localização. Por favor, use a sua localização real para prosseguir.',
  'LocationNotProvided': 'Sua localização deve estar ativada para usar este recurso.',
  'Login': 'Entrar',
  'Logout': 'Sair',
  'LossAdjuster': 'Perito',
  'LossCause': 'Causa das perdas',
  'LossCauseRecognized': 'Causa de perda reconhecida',
  'LossEstimation': 'Estimativa de perdas',
  'LossEventDate': 'Data do evento de perda',
  'Losses': 'Perdas',
  'LowHailRisk': 'Risco leve de danos causados por granizo',
  'LowPrecipitation': 'Precipitação baixa (10-15 mm/semana)',
  'LowSoilMoisture': 'Um pouco mais seco que o normal',
  'LowTemperature': 'Risco reduzido de geada (-1°C<Tmin<2°C)',
  'LowVegetation': 'Saúde vegetativa ligeiramente abaixo do normal (-25%)',
  'LowWind': 'Vento forte (17-24.5 m/s)',
  'Lower': 'Inferior',
  'LowerSoilMoisture': 'Mais seco que o normal',
  'LowerTemperature': 'Risco moderado de geada (-5°C<Tmin<-1°C)',
  'LowerVegetation': 'Saúde vegetativa abaixo do normal (-40%)',
  'Lowest': 'Mínimo do talhão',
  'LowestSoilMoisture': 'Significativamente mais seco que o normal',
  'LowestTemperature': 'Risco elevado de geada (-15°C<Tmin<-5°C)',
  'LowestVegetation': 'Saúde vegetativa muito abaixo do normal (-55%)',
  'MAR': 'Marrocos',
  'MDA': 'Moldávia',
  'MEX': 'México',
  'ManageUserAccountAndSettings': 'Gerencie suas contas e configurações',
  'Manager': 'Gerente',
  'Map': 'Mapa',
  'MaxSubsidiesPerMinute': 'Subsídios máximos por minuto',
  'MeasureDensity': 'Medir densidade',
  'Median': 'Mediana',
  'Merge': 'Unir',
  'Method': 'Método',
  'MixedTemperature': 'Zona mista de oscilação de temperatura elevada',
  'ModerateHailRisk': 'Risco moderado de danos causados por granizo',
  'ModeratePrecipitation': 'Precipitação moderada (15-30 mm/semana)',
  'ModerateWind': 'Vendaval (24.5-28.5 m/s)',
  'Months': 'Meses',
  'MoreDetails': 'Mais detalhes',
  'MoreSpecificHarvestDetected': 'Colheita mais específica detectada e substituirá a colheita existente.',
  'MunicipalMedianVegetation': 'Vegetação Mediana do Município',
  'Municipality': 'Município',
  'MustAddCustomer': 'Deve adicionar pelo menos um cliente',
  'MustAddEmail': 'Deve fornecer um e-mail válido',
  'MustSelectACustomer': 'Deve selecionar um cliente para continuar',
  'MustSelectCropAndYear': 'Você deve selecionar uma cultura e um ano',
  'NDVIRange': 'Faixa de NDVI',
  'NationalYield': 'Produtividade nacional',
  'NeedCurrentHarvest': 'Você deve adicionar uma cultura e uma colheita para esta temporada antes de continuar',
  'NeedHelpContact': 'Problemas com o acesso? - Contate-nos',
  'New': 'Novo',
  'NewValue': 'Novo valor',
  'NewVersionWarning': 'Uma nova versão do aplicativo está disponível.',
  'NewVisit': 'Nova visita',
  'Next': 'Seguir',
  'No': 'Não',
  'NoClaims': 'Sem reclamações',
  'NoData': 'Nenhum dado encontrado.',
  'NoDataFoundForYourFilters': 'Não temos dados para os seus critérios de filtro atuais.',
  'NoFarmEditors': 'Nenhum editor atribuído a este campo',
  'NoGpsSupport': 'Este dispositivo não tem uma unidade de GPS.',
  'NoHailRisk': 'Sem risco de perdas/danos',
  'NoOfflineFarms': 'Nenhuma fazenda salva para uso offline.',
  'NoOngoingVisit': 'Por favor, clique no (+) acima para iniciar uma nova visita.',
  'NoPrecipitation': 'Sem precipitação (<5 mm/semana)',
  'NoRainstorm': 'Sem tempestade (<15 mm/h)',
  'NoSampleDateSelectionWarning': 'Nenhuma data de amostragem foi selecionada. Selecione pelo menos um para evitar um relatório vazio.',
  'NoSheetDataError': 'O Excel importado não tinha uma planilha \'Data\'. Use o modelo',
  'NoTelepacAvailable': 'Telepac não disponível',
  'NoVariety': 'Sem variedade',
  'NoWind': 'Brisa ou sem vento (<17 m/s)',
  'NonCompensableLosses': 'Perdas não indenizáveis',
  'NonIrrigated': 'Não Irrigado',
  'NonOrganic': 'Não orgânico',
  'NormalSoilMoisture': 'Situação normal',
  'NormalTemperature': 'Sem de Risco Geada/Calor (2°C<Tmin; Tmax<25°C)',
  'NormalVegetation': 'Condição normal da vegetação',
  'NotAffected': 'Não afetado',
  'NotAvailable': 'Não disponível',
  'NumberMatchingFields': 'Número de talhões correspondentes',
  'NumberMatchingSamples': 'Número de amostras correspondentes',
  'NumberOfFields': 'Número de talhões',
  'NumberOfGrainsPerRow': 'Número de grãos por linha',
  'NumberOfLossAdjusters': 'Número de especialistas',
  'NumberOfPolicies': 'Número de apólices',
  'NumberOfRowsPerHead': 'Número de linhas por cabeça',
  'NumberOfSamples': 'Número de amostras',
  'Occurence': 'Ocorrência',
  'OfFieldArea': 'de área do talhão',
  'OfficialHarvestArea': 'Área oficial de colheita',
  'Offline': 'Sem conexão',
  'OfflineAreaMaxLimitReachedMsg': 'Limite máximo de área offline atingido.',
  'OfflineAreaTutorialDownload': 'Toque no botão de download para salvar blocos de mapa para uso offline nas áreas selecionadas.',
  'OfflineAreaTutorialPanMap': 'Mova o mapa usando um dedo.',
  'OfflineAreaTutorialPinchZoom': 'Aperte o mapa para aumentar ou diminuir o zoom.',
  'OfflineAreaTutorialSelectArea': 'Toque em uma área para selecioná-la ou desmarcá-la.',
  'OfflineAreas': 'Áreas off-line',
  'OfflineFarms': 'Fazendas off-line',
  'Ok': 'OK',
  'Ongoing': 'Em progresso',
  'OpenCamera': 'Câmera Aberta',
  'OpenFarm': 'Abra a fazenda',
  'OpenPolicy': 'Abra a apólice',
  'OpenVisit': 'Visita aberta',
  'Or': 'Ou',
  'Organic': 'Orgânico',
  'OrganicQuestionMark': 'Orgânico?',
  'OtherCompensableLosses': 'Outras perdas indenizáveis',
  'OtherFields': 'Outros campos',
  'OverrideCalculation': 'Substituir a produtividade agregada',
  'OverrideEstimatedYield': 'Substituir rendimento estimado',
  'Overview': 'Visão geral',
  'PER': 'Peru',
  'POL': 'Polónia',
  'PRY': 'Paraguai',
  'Password': 'Senha',
  'PasswordMissing': 'Adicione sua senha!',
  'PastMonth': 'Mês passado',
  'PastReportsClickToRefresh': 'Relatórios anteriores - clique para atualizar:',
  'PastWeek': 'Semana passada',
  'PastYear': 'Ano passado',
  'Pause': 'Colocar em pausa',
  'Paused': 'Pausado',
  'PeakVegetationDate': 'Data da vegetação máxima',
  'PendingDownload': 'Download pendente',
  'PendingUpload': 'Carregamento pendente',
  'PerCropSignature': 'Assinatura por cultura',
  'Phone': 'Telefone',
  'Photo': 'Foto',
  'Photos': 'Fotos',
  'PhotosAndReferences': 'Fotografias e referências',
  'PixelResolution': 'Resolução de pixels',
  'PlanetTiles': 'Imagens Planet',
  'PlanetTilesBoundsWarning': 'Nenhum bloco está disponível para a data e área selecionadas. Selecione uma data ou área diferente.',
  'PlantDensity': 'Densidade de plantas',
  'PlantsCollected': 'Plantas colhidas',
  'PleaseAddFields': 'Adicione alguns talhões para continuar',
  'PleaseConnect': 'Sem conexão à Internet. Para fazer login, conecte-se à Internet!',
  'PleaseEnableLocation': 'Favor, compartilhe sua localização.',
  'PleaseEnablePhotoPermissions': 'Para continuar, favor habilitar a cámera nas configurações',
  'PleaseEnterEmail': 'Favor, digite seu e-mail.',
  'PleaseEnterValue': 'Favor, insira um valor',
  'PleaseReviewReportBeforeSigning': 'Por favor, revise o relatório antes de assinar',
  'PleaseSelectClaim': 'Selecione a reivindicação',
  'PleaseSelectDate': 'Selecione uma data.',
  'PleaseSelectFarm': 'Selecione uma fazenda para continuar',
  'PleaseSelectField': 'Favor selecionar um talhão para continuar',
  'PleaseSelectLocation': 'Selecione um local para continuar.',
  'PleaseSelectPolicy': 'Selecione uma apólice para continuar',
  'PleaseUpgradeLatest': 'A sua versão do GreenTriangle está desatualizada. Atualize para a versão mais recente',
  'PointOrZone': 'Ponto/Zona',
  'Policies': 'Apólices',
  'Policy': 'Apólice',
  'PolicyFormComment': 'Informações adicionais da apólice',
  'PolicyNumber': 'Número da apólice',
  'PolicyProcessingStartEnd': 'Início e fim do processamento de políticas',
  'Polygon': 'Polígono',
  'Portfolio': 'Portfólio',
  'PortfolioReport': 'Relatório de portfólio',
  'PotentialDuplicates': 'As seguintes entradas parecem semelhantes. Pondere selecionar uma das seguintes opções',
  'Practice': 'Conveniente',
  'PrecipitationInfo': 'A precipitação semanal total denota água acumulada e congelada, incluindo chuva e neve, que cai na superfície da Terra em 7 dias. Não inclui neblina, orvalho ou precipitação que evapora na atmosfera antes de pousar na superfície da Terra.',
  'Preview': 'Pré-visualização',
  'PreviewAndSend': 'Pre-visualizar e Enviar',
  'Previous': 'Anterior',
  'PreviousDate-s': 'Data(s) previa(s)',
  'PriceRetainedByExpert': 'Preço retido pelo especialista',
  'Primary': 'Primária',
  'PrimaryYieldLoss': 'Perda de produtividade',
  'PrivacyPolicy': 'Política de privacidade',
  'Processing': 'Processamento',
  'Productivity': 'Produtividade',
  'ProductivityInfo': 'O índice de produtividade estima o quão produtivo o talhão tem sido historicamente',
  'QuoteProcessingStartEnd': 'Início e fim do processamento de cotações',
  'ROU': 'Roménia',
  'RUS': 'Rússia',
  'RainstormInfo': 'Precipitação máxima por hora nesta semana.',
  'RainstormLevel1': 'Tempestade nível 1 (15-25 mm/h)',
  'RainstormLevel2': 'Tempestade nível 2 (25-35 mm/h)',
  'RainstormLevel3': 'Tempestade nível 3 (35-50 mm/h)',
  'RainstormLevel4': 'Tempestade nível 4 (>50 mm/h)',
  'Recipients': 'Destinatários',
  'RecognizedHazard': 'Evento identificado',
  'Reference': 'Referência',
  'Refresh': 'Atualizar',
  'Region': 'Região',
  'RegionLevel': 'Nível de região',
  'RegionLevel_commune': 'Comuna',
  'RegionLevel_country': 'País',
  'RegionLevel_state': 'Estado',
  'RegionalStatistic': 'Estatísticas Regionais',
  'Regions': 'Regiões',
  'RelativeScoring': 'Pontuação Relativa',
  'RelativeScoringInfo': 'A pontuação relativa reflete a diferença da pontuação de um talhão com a pontuação mediana dos talhões de uma região (usando cerca de 100 campos amostrais), enquanto a pontuação percentil posiciona o talhão dentro dos campos da região (5 sendo o percentil superior, 1 o percentil inferior, 3 a mediana)',
  'Remove': 'Remover',
  'RemoveCrop': 'Eliminar cultura',
  'RemoveFarm': 'Remover fazenda',
  'RemoveField': 'Remover talhão',
  'RemoveHarvest': 'Remover colheita',
  'RemoveLoss': 'Remover perda',
  'RemovedGroups': 'Grupos removidos',
  'RenewHarvests': 'Renovar safra',
  'ReportDate': 'Data do relatório',
  'ReportDoesntExist': 'O relatório não existe',
  'ReportGenerationPageComment': 'Comentário geral sobre o relatório',
  'ReportNoFields': 'Esta fazenda não possui talhões para a criação de um relatório!',
  'Reset': 'Reiniciar',
  'ResetLink': 'Enviar e-mail de redefinição',
  'Restore': 'Restaurar',
  'Resume': 'Retomar',
  'Risks': 'Riscos',
  'RowDistanceCollected': 'Distância das linhas colhidas',
  'RowsAndWidth': 'Linhas e distância entre linhas',
  'SRB': 'Sérvia',
  'Sample': 'Amostra',
  'Sample-s': 'Amostra(s)',
  'SampleCropTitle': 'Número de amostras por cultura',
  'SampleDate': 'Data da amostra',
  'SampleDate-s': 'Data(s) da amostra',
  'SampleFormComment': 'Comentário / Observação',
  'SampleFormMissingImagesWarning': 'Você não tirou uma foto para esta amostra. Adicione um para continuar.',
  'SampleLocation': 'Local da amostra',
  'SampleLocationInaccurate': 'Localização imprecisa - Ative a Localização precisa, se ainda não estiver. Em seguida, clique em "Utilizar a minha localização" novamente.',
  'SampleLocationOutsideField': 'O local de amostragem não está dentro do talhão selecionado.',
  'SampleLocationPrompt': 'Escolha o campo correspondente no mapa e pressione firmemente no mapa para localizar a amostra ou pressione a seta azul à esquerda para usar sua localização atual.',
  'SampleType': 'Tipo de amostra',
  'SampledArea': 'Área amostrada',
  'SampledBy': 'Amostrado por',
  'Samples': 'Amostras',
  'SamplingDate-s': 'Data(s) de amostragem',
  'Save': 'Salvar',
  'SaveOffline': 'Salvar off-line',
  'Saving': 'Salvando...',
  'Score': 'Pontuação',
  'Search': 'Procurar',
  'SearchAddTipLabel': 'Combinar termos de pesquisa',
  'SearchExampleDescAddedBy': 'Introduza um endereço de e-mail para mostrar as amostras adicionadas por esse usuário, bem como os campos e as fazendas dessas amostras',
  'SearchExampleDescAddress': 'Introduza um endereço para centrar novamente o mapa nessa localização.',
  'SearchExampleDescCropCondition': 'Introduza uma condição da cultura para destacar as amostras em que a condição da mesma foi avaliada de uma determinada forma.',
  'SearchExampleDescFarm': 'Encontre uma fazenda, os seus talhões e amostras através do nome ou do endereço',
  'SearchExampleDescHarvest': 'Encontre todos os elementos relacionados com uma colheita específica, inserindo um tipo de colheita, estação ou ano. Se introduzir um tipo de colheita, os resultados serão limitados a este ano. Pode pesquisar por anos de colheita mais antigos numa segunda pesquisa.',
  'SearchExampleDescPolicy': 'Encontre todos os elementos relacionados com um número de apólice',
  'SearchExampleLabelAddedBy': 'encontrar amostras adicionadas por um endereço de e-mail',
  'SearchExampleLabelAddress': 'cidade, endereço ou país',
  'SearchExampleLabelCropCondition': 'encontrar amostras para uma determinada condição de colheita (por exemplo, "ruim")',
  'SearchExampleLabelFarm': 'pesquisar por nome e endereço da fazenda',
  'SearchExampleLabelHarvest': 'pesquisar por colheita, por exemplo, "trigo"',
  'SearchExampleLabelPolicy': 'pesquisar por número de apólice',
  'SearchExampleLabelUserGroup': 'encontrar item em portfólio(s) específico(s)',
  'SearchForClaimNumber': 'Procurar o número do sinistro',
  'SearchVisit': 'Localizar inspeção não atribuída',
  'Secondary': 'Secundária',
  'SecondaryYieldLoss': 'Perda secundária',
  'SeedWeightPerHead': 'Pesso por capitulo (girassol)',
  'Select': 'Seleção',
  'SelectAll': 'Seleccionar tudo',
  'SelectAppellation': 'Selecione a denominação',
  'SelectCostType': 'Selecionar tipo de custo',
  'SelectCropCondition': 'Selecione a condição da cultura',
  'SelectCropType': 'Selecione um tipo de cultura',
  'SelectCurHarvest': 'Selecione a cultura atual',
  'SelectDate': 'Selecione a data',
  'SelectFarm': 'Selecione uma fazenda',
  'SelectFarmsForOfflineAccess': 'Selecione fazendas para acesso offline',
  'SelectField': 'Selecione um talhão',
  'SelectFieldCurHarvest': 'Selecione o ano da colheita',
  'SelectFieldCurPolicy': 'Selecione a apólice do talhão',
  'SelectHarvest': 'Selecione colheita',
  'SelectHarvestYear': 'Selecione o ano da colheita',
  'SelectLossCause': 'Escolher motivo da perda',
  'SelectOneGroup': 'Selecione itens em apenas um portfólio',
  'SelectPac': 'Selecione um arquivo válido (.ZIP, .XML, .KML, GeoJSON). Se você tiver certeza de que este arquivo está correto, entre em contato conosco pelo e-mail support@green-triangle.com.',
  'SelectPolicy': 'Selecionar uma apólice',
  'SelectPortfolio': 'Selecionar portfólio',
  'SelectReportType': 'Selecione o tipo de relatório',
  'SelectSampleType': 'Selecionar tipo de amostra',
  'SelectSubplotId': 'Selecione o ID da subparcela',
  'SelectVariety': 'Selecione a variedade',
  'SelectVegetationStage': 'Selecionar o estágio da cultura (BBCH)',
  'SelectVisitType': 'Selecionar tipo de inspeção',
  'SelectWillOverwriteShape': 'Tem certeza que deseja escolher esse perímetro? O perímetro atual será substituído.',
  'SelectYear': 'Selecione o ano',
  'SelectedAppellation': 'Denominação selecionada',
  'SelectedCrops': 'Culturas selecionadas',
  'SelectedField': 'Campo selecionado',
  'Send': 'Enviar',
  'SendFeedback': 'Enviar comentários',
  'SendTo': 'Enviar para',
  'Sending': 'Enviando',
  'Sent': 'Enviado',
  'Settings': 'Configurações',
  'SevereHailRisk': 'Risco severo de danos provocados por granizo',
  'SeverePrecipitation': 'Precipitação severa (>75 mm/semana)',
  'SevereWind': 'Furacão (>32.5 m/s)',
  'ShapeTracerAddPoint': 'Clique no mapa para adicionar um ponto ou clique num ponto para alterar a sua posição ou eliminá-lo.',
  'ShapeTracerEditPoint': 'Desloque o ponto através do marcador azul ou clique em qualquer lugar para voltar atrás e adicionar pontos.',
  'Share': 'Compartilhar',
  'ShowAll': 'Mostre tudo',
  'ShowDataOnMap': 'Mostrar dados no mapa',
  'ShowLastAdded': 'Mostrar último adicionado',
  'ShowOnMap': 'Mostrar no mapa',
  'ShowSamplesAddedIn': 'Mostrar amostras adicionadas no último:',
  'SignAndClose': 'Assinar e Fechar',
  'SignatoryName': 'Nome do signatário',
  'SignatoryNames': 'Nome dos assinantes',
  'Signature': 'Assinatura',
  'Signatures': 'Assinatura',
  'SignedByFarmer': 'Assinado pelo fazendeiro',
  'SnowIce': 'Neve/Gelo',
  'SoilMoistureInfo': 'O índice de anomalia de umidade do solo quantifica o status de umidade camada com 40 cm de profundidade em relação à média dos últimos anos (10 anos) para o mesmo período.',
  'SomethingWentWrongError': 'Algo deu errado.',
  'SowingDate': 'Data de plantio',
  'SowingDensity': 'Densidade de plantas',
  'SpaceBetweenPlants': 'Espaço entre plantas',
  'SpaceBetweenRows': 'Espaço entre linhas',
  'SquareArea': 'Área da Praça',
  'SquareMeterCut': 'Corte por metro quadrado / densidade de plantas',
  'SquareSide': 'Lado da Praça',
  'SquareSize': 'Tamanho do quadrado',
  'Stability': 'Estabilidade',
  'StabilityInfo': 'A pontuação de estabilidade mede a estabilidade da produtividade inferida de um talhão ao longo do tempo',
  'StartDate': 'Data de início',
  'StartDateTime': 'Data e hora de início',
  'StartingYield': 'Produtividade viável',
  'Status': 'Status',
  'SubjectToValidationOfReseedingByExpert': 'Sujeito a validação de ressemeadura pelo especialista',
  'Subplots': 'Subtramas',
  'SubsidySettings': 'Configurações de subsídios',
  'Summary': 'Resumo',
  'SupportedSurface': 'Superfície suportada',
  'SureAddSampleWithoutField': 'Tem certeza de que deseja adicionar uma amostra sem um talhão?',
  'SureCancel': 'Tem certeza de que deseja cancelar antes de salvar os dados?',
  'SureCloseVisit': 'Tem certeza de que deseja encerrar a inspeção? O relatório não foi assinado.',
  'SureCloseVisitPartial': 'Tem certeza de que deseja encerrar a inspeção? O relatório foi assinado apenas parcialmente.',
  'SureDeleteFarm': 'Tem a certeza de que deseja eliminar esta Fazenda?',
  'SureDeleteField': 'Tem a certeza de que deseja eliminar este talhão?',
  'SureDeleteHarvest': 'Tem certeza que deseja eliminar a colheita?',
  'SureDeleteSample': 'Tem a certeza de que deseja eliminar esta amostra?',
  'SureDeleteVisit': 'Tem certeza que deseja excluir esta inspeção?',
  'SureReset': 'Tem certeza de que deseja redefinir? Você perderá todas as suas alterações off-line.',
  'SureSelectObject': 'Tem a certeza que, em vez disso, pretende selecionar o seguinte:',
  'SureSignOut': 'Tem certeza de que deseja sair? Os dados salvos no modo off-line serão perdidos se desconectados.',
  'SurfacePAC': 'PAC de superfície',
  'SurfaceTemperatureInfo': 'A camada mostra as temperaturas mínima e máxima semanal em superficie, indicando o risco de geada e golpe de calor',
  'Syncing': 'Sincronizando',
  'TableNoRowsFound': 'Nenhuma linha encontrada',
  'TableOf': 'de',
  'TablePage': 'Página',
  'TableRows': 'Linha',
  'TelepacAvailableFor': 'Telepac carregado para',
  'TelepacNr': 'Número Telepac',
  'TelepacReport': 'Importação de PAC',
  'TemperatureInfo': 'Esta camada apresenta as temperaturas mínimas e máximas semanais, de forma a detetar o risco de formação de geada e de ondas de calor.',
  'Tertiary': 'Terciária',
  'TertiaryYieldLoss': 'Perda terciária',
  'ThousandKernelWeightGrams': 'Peso de 1000 grãos (gr)',
  'TopNCropsByHarvestArea': 'Principais culturas por área de colheita',
  'Total': 'Total',
  'TotalArea': 'Área total',
  'TotalCultivatedArea': 'Área cultivada (total)',
  'TotalEarsDetected': 'Total de ouvidos detectados',
  'TotalHarvestArea': 'Área total de colheita',
  'TotalLoss': 'Perda total',
  'TotalYieldPctLoss': 'Perda de produtividade total (%)',
  'UKR': 'Ucrânia',
  'URY': 'Uruguai',
  'USA': 'EUA',
  'UnableToUpload': 'Não é possível carregar',
  'UnassignMyself': 'Cancelar minha atribuição',
  'Uncategorized': 'Sem categoria',
  'Undo': 'Anular',
  'UnguaranteedLosses': 'Perdas Não Garantidas',
  'Unit': 'Unidade',
  'UnitsPerPlant': 'Unidades por planta',
  'UnknownErrorOccurred': 'Ocorreu um erro desconhecido',
  'UpcomingInspection': 'Próxima inspeção',
  'Update': 'Atualizar',
  'UpdateMemberships': 'Atualizar assinaturas',
  'UploadPACFile': 'Enviar o arquivo PAC',
  'Usage': 'Uso',
  'UseMyLocation': 'Utilizar a minha localização',
  'User': 'Do utilizador',
  'UserAdminNoAccess': 'Não há sobreposição entre os clientes dos usuários selecionados e seus direitos de administrador de usuário.',
  'UserAdminNoCommon': 'Os usuários selecionados não têm empresa comum.',
  'UserAdministration': 'Administração do usuário',
  'UserGroup': 'Portfólio',
  'Validation': 'Validação',
  'VegetationInfo': 'A anomalia de vitalidade da planta oferece uma medida alternativa da condição relativa das culturas em comparação aos anos anteriores (desde 2001). Este indicador pode ser usado para monitorar áreas onde a vegetação está estressada ou como um parâmetro para detectar uma onda de calor ou possível seca.',
  'VegetationStage': 'Fase da cultura',
  'VeryHighPrecipitation': 'Precipitação muito alta (50-75 mm/semana)',
  'VeryLowPrecipitation': 'Precipitação muito baixa (5-10 mm/semana)',
  'ViewThisInstead': 'Gostaria de sair deste formulário e abrir, em vez disso, o seguinte:',
  'VinificationRatio': 'Relação de Vinificação (kg/hl)',
  'Visit': 'Inspeção',
  'VisitAssign': 'Assinar vistoria',
  'VisitCandidateStatus_accepted': 'Aceitaram',
  'VisitCandidateStatus_assigned': 'Atribuído',
  'VisitCandidateStatus_rejected': 'Rejeitado',
  'VisitList': 'Lista de vistoria',
  'VisitMode': 'Modo de Vistoria',
  'VisitReport': 'Relatório de inspeção',
  'VisitReportEmailInfoMsg': 'Isso enviará o relatório de visita assinado para os endereços de e-mail selecionados.',
  'VisitType': 'Tipo de vistoria',
  'VisitValidation': 'Validação de visita',
  'VisitYear': 'Ano da visita',
  'Visited': 'Visitado',
  'Visits': 'Visitas',
  'Volatility': 'Volatilidade',
  'WebApp': 'Aplicativo Web',
  'WeightPerPlant': 'Peso médio por planta',
  'WeightPerUnit': 'Peso por unidade',
  'WeightedAverage': 'Média ponderada',
  'WindInfo': 'A camada de rajada de vento máxima semanal representa a rajada de vento máxima registrada nos últimos 7 dias a uma altura de dez metros acima da superfície da Terra.',
  'Withdrawal': 'Cancelar',
  'Withdrawn': 'Retirado',
  'WouldLikeToRestore': 'Encontramos dados para este formulário que não foram salvos devido a uma falha ou atualização. Deseja recuperar esses dados?',
  'Year': 'Ano',
  'YearEg': 'Ano (por exemplo, 2019)',
  'Yes': 'Sim',
  'YieldAndLoss': 'Produtividade e perda',
  'YieldCalcUnsupportedCrop': 'A calculadora não é suportada para este tipo de cultura. Atualmente, só é possível atribuir densidade de plantas.',
  'YieldEstimationMethod': 'Método de estimativa de produtividade',
  'YieldLoss': 'Queda de produtividade',
  'YieldLossInputHidden': 'A entrada de perda de rendimento está desabilitada porque a causa da perda não foi reconhecida.',
  'YieldRatio': 'Índice de produtividade',
  'YieldTooLarge': 'Produtividade muito alta',
  'YouMayTryAgain': 'Você pode tentar novamente',
  'ZAF': 'África do Sul',
  'ZoomInViewLayer': 'Para ver esta camada, amplie a imagem.',
  'ZoomOutViewLayer': 'Para ver esta camada, reduza a imagem.',
  'additional-costs': 'Costos adicionais',
  'agroforestry': 'Agroflorestal',
  'alfalfa': 'Alfalfa',
  'allSynced': 'Tudo foi sincronizado com o banco de dados',
  'almond': 'Amêndoa',
  'ananas': 'Abacaxi',
  'angelica': 'Angélica',
  'anise': 'Anis',
  'apples': 'Maçãs',
  'apples-cider': 'Maçã (cidra)',
  'apples-pre-prod': 'Maçã (5 anos ou menos)',
  'apricots': 'Damasco',
  'apricots-pre-prod': 'Damascos (5 anos ou menos)',
  'aromatic-plants': 'Plantas aromáticas',
  'artichoke': 'Alcachofra',
  'asparagus': 'Espargos',
  'avocado': 'Abacate',
  'bananas': 'Banano',
  'barley': 'Cevada',
  'barley-6-row': 'Cevada de 6 fileiras',
  'barley-malting': 'Cevada cervejeira',
  'barley-seeds': 'Cevada (semente híbrida)',
  'base': 'Base',
  'basic': 'Básico',
  'basil': 'Manjericão',
  'bbch-00-grapes': 'A. Botão de inverno - Uva',
  'bbch-05-grapes': 'B. Botão lanoso - Uva',
  'bbch-09-grapes': 'C. Budbreak (ponta verde) - Uva',
  'bbch-11-grapes': 'D. Emergência foliar - Uva',
  'bbch-13-grapes': 'E. Folhas separadas - Uva',
  'bbch-53-grapes': 'F. Inflorescências visíveis - Uva',
  'bbch-55-grapes': 'G. Inflorescências separadas - Uva',
  'bbch-57-grapes': 'H. Flores separadas - Uva',
  'bbch-64-grapes': 'I. Floração - Uva',
  'bbch-71-grapes': 'J. Frutificação - Uva',
  'bbch-75-grapes': 'K. Tamanho da ervilha das bagas - Uva',
  'bbch-77-grapes': 'L. Bagas tocando - Uva',
  'bbch-81-grapes': 'M. Pre-maturaçao - Uva',
  'bbch-89-grapes': 'N. Maturidade - Uva',
  'bbch-93-grapes': 'O. Maturação de galhos/Queda foliar - Uva',
  'beans': 'Favas e favas',
  'beans-dry': 'Feijão seco',
  'beans-green': 'Feijão verde',
  'beans-red': 'Feijão vermelho',
  'beans-seeds': 'Feijão (grão)',
  'beans-white': 'Feijão branco',
  'beetroot': 'Beterraba',
  'benchmark-yield': 'Produtividade de referência',
  'black-medick': 'Alfalfa-lupulina',
  'blackberry': 'Amora',
  'blackcurrant': 'Groselha preta',
  'blueberry': 'Mirtilo',
  'broccoli': 'Brócolis',
  'bromes': 'Capim-bromes',
  'buckwheat': 'Trigo sarraceno',
  'burdock': 'Bardana',
  'cabbages': 'Repolho',
  'cabbages-feed': 'Repolho forrageiro',
  'cabbages-red': 'Repolho roxo',
  'camelina': 'Camelina',
  'caraway': 'Alcaravia',
  'cardoon': 'Cardo',
  'carob': 'Alfarroba',
  'carrots': 'Cenoura',
  'carrots-feed': 'Cenoura forrageira',
  'carrots-young': 'Cenoura',
  'cassava': 'Mandioca',
  'castor-bean': 'Mamona',
  'castration-costs': 'Custos de Castração ($)',
  'cauliflowers': 'Couve-flor',
  'cedrate': 'Cidreira',
  'celeri-leaves': 'Salsão (folha)',
  'celeri-root': 'Salsão (raiz)',
  'celery': 'Salsão',
  'cereals': 'Cereais',
  'chamomile': 'Camomila',
  'chard': 'Acelga',
  'cherries': 'Cereja',
  'chervil': 'Cerefólio',
  'chestnut': 'Castanha',
  'chia': 'Chia',
  'chickpeas': 'Grão de bico',
  'chicory': 'Chicória',
  'chilli': 'Pimenta',
  'chives': 'Cebolinha',
  'citrus': 'Citrus',
  'claim-all': 'Encerramento final da missão',
  'claim-delivery': 'Fechamento final dos resultados da colheita',
  'claim-some': 'Fechamento definitivo da cultura',
  'claims-visit': 'Sinistro-Final',
  'claims-visit-file': 'estimativa',
  'clementines': 'Clemendinas',
  'clover': 'Trevo',
  'cocoa': 'Cacau',
  'coffea': 'Café',
  'cold': 'Frio',
  'compensation-costs': 'Indenização de costos',
  'connected': 'O aplicativo está conectado à internet',
  'coriander': 'Coentro',
  'corn': 'Milho',
  'corn-grain': 'Milho (grão)',
  'corn-popcorn': 'Milho pipoca',
  'corn-seeds': 'Milho (semente)',
  'corn-seeds-fertile': 'Milho (semente, fértil)',
  'corn-seeds-sterile': 'Milho (semente, estéril)',
  'corn-silage': 'Milho (silagem)',
  'corn-sweet': 'Milho doce',
  'corn-sweet-seeds-fertile': 'Milho doce (semente, fértil)',
  'corn-sweet-seeds-sterile': 'Milho doce (semente, estéril)',
  'corn-waxy': 'Milho glutinoso',
  'cornsalad': 'Milho',
  'cotton': 'Algodão',
  'cover-crop': 'Culturas de Cobertura',
  'cranberry': 'Oxicoco',
  'cress': 'Agrião',
  'crop-mon': 'Monitoramento das culturas',
  'crop_condition': 'Estado das culturas',
  'crosne': 'Alcachofra Chinesa',
  'cucumbers': 'Pepino',
  'cumin': 'Cominho',
  'cynara': 'Alcachofra',
  'dactylis': 'Dáctilo',
  'damson': 'Ameixa damson',
  'decree': 'Decreto oficial',
  'development': '4. Desenvolvimento de partes de plantas que podem ser colhidas',
  'dill': 'Endro',
  'disease': 'Doença',
  'downpour': 'Chuva torrencial',
  'drought': 'Estiagem',
  'eggplant': 'Beringela',
  'elder': 'Sambucus',
  'endives': 'Endívia',
  'excess-water': 'Agua em excesso',
  'excessive-air-humidity': 'Excesso de umidade (ar)',
  'expected-loss': 'Costo de perda esperada',
  'fallow-land-perennial': 'Área de pousio (perene)',
  'fallow-land-spring': 'Área de pousio (primavera)',
  'false': 'Não',
  'fennel': 'Erva doce',
  'fenugreek': 'Trigonella',
  'festuca': 'Festuca',
  'field-beans': 'Feijão',
  'field-beans-seeds': 'Feijão (grão)',
  'field-crops': 'Grandes culturas',
  'field-mustard': 'Nabo',
  'figs': 'Figo',
  'fire': 'incêndio',
  'first-crop': 'Primeira colheita',
  'flageolet-bean': 'Feijão (flageolet)',
  'flood': 'Precipitação intensa',
  'flood-risk': 'Risco de inundação',
  'flowering': 'Floração',
  'fodder-legumes': 'Leguminosas forrageiras',
  'forest': 'Floresta',
  'foxtail-millet': 'Milheto painço',
  'frost': 'Geada',
  'fruit-development': '7. Desenvolvimento do fruto',
  'full': 'Acesso total',
  'garlic': 'Alho',
  'gentiana': 'Gentiana',
  'germination': 'Germinação',
  'gherkins': 'Maxixe',
  'ginger': 'Gengibre',
  'go': 'Vá!',
  'good': 'Bom',
  'gooseberries': 'Groselha',
  'gourd': 'Cabaça',
  'grapefruit': 'Grapefruit',
  'grapes': 'Uva',
  'grapes-food': 'Uva mesa',
  'grapes-juice': 'Uva (suco)',
  'grapes-must': 'Uva (mosto)',
  'grapes-nursery': 'Viveiro de videira',
  'grapes-table': 'Uva (mesa)',
  'grassland': 'Prados',
  'grassland-perennial': 'Pastagens (perenes)',
  'green-peas': 'Ervilha verde',
  'group-allocator': 'Alocador de grupo',
  'hail': 'Granizo',
  'harvest': 'Colhido',
  'harvest_crop': 'Tipo de cultura',
  'harvested': 'Colhido',
  'harvesting-costs': 'Custos de Colheita ($)',
  'hazelnuts': 'Avelã',
  'heat-wave': 'Onda de calor',
  'heatstroke': 'Golpe de calor',
  'heavy-rainfall': 'Chuva forte',
  'hemp': 'Cânhamo',
  'hemp-paper': 'Cânhamo (papel)',
  'hemp-seeds': 'Cânhamo (semente)',
  'high-res-mapbox': 'Alta resolução',
  'historical-yield': 'Relação de produtividade histórica',
  'hop': 'Lúpulo',
  'hyssop': 'Hyssopo',
  'inflorescence': '5. Emergência da inflorescência / Espiga',
  'interfield': 'Comparação de talhões',
  'interfield-a': 'Sem vegetação',
  'interfield-b': 'Vegetação esparsa',
  'interfield-c': 'Vegetação baixa',
  'interfield-d': 'Vegetação moderada',
  'interfield-e': 'Vegetação moderada a densa',
  'interfield-f': 'Vegetação densa',
  'interfield-g': 'Atividade de vegetação elevada',
  'interfield-h': 'Atividade de vegetação muito elevada',
  'interfield-i': 'Atividade da vegetação máxima',
  'interyield': 'Estimativa de produtividade',
  'interyield-a': 'Produtividade extremadamente baixa',
  'interyield-b': 'Produtividade muito baixa',
  'interyield-c': 'Produtividade baixa',
  'interyield-d': 'Produtividade de baixa até moderada',
  'interyield-e': 'Produtividade moderada',
  'interyield-f': 'Produtividade de moderada até alta',
  'interyield-g': 'Produtividade alta',
  'interyield-h': 'Produtividade muito alta',
  'interyield-i': 'Produtividade máxima',
  'intrafield': 'Variabilidade intra-talhão',
  'itemsToUpload': 'Ainda existem elementos a serem transferidos para o banco de dados.',
  'jerusalem-artichoke': 'Alcachofra de jerusalem',
  'kale': 'Couve (frizada)',
  'kiwi': 'Kiwi',
  'lack-of-sunlight': 'Baixa luminosidade',
  'lavandin': 'Lavandin',
  'lavender': 'Lavanda',
  'leaf-development': '1. Desenvolvimento da folha',
  'leek': 'Alho-poró',
  'lemon-balm': 'Erva-cidreira',
  'lemons': 'Limoeiro',
  'lentils': 'Lentilhas',
  'lentils-berry': 'Lentilhas verdes',
  'lentils-puy': 'Lentilhas verdes (puy)',
  'lettuce': 'Alface',
  'linden': 'Tília',
  'lineseed-textile-seeds': 'Linhaça',
  'linseed': 'Linho',
  'linseed-oilseed': 'Linho (azeite)',
  'linseed-textile': 'Linho têxtil',
  'linseed-textile-fiber': 'Linho (têxtil)',
  'linseed-textile-seeds': 'Linho (semente)',
  'linseed-textile-straw': 'Linho (fibra)',
  'lotus': 'Lotus',
  'lupin': 'Tremoço',
  'lupin-sweet': 'Tremoço',
  'mandarins': 'Tangerina',
  'mangos': 'Mangueira',
  'meadow-grass': 'Capim-prado',
  'medicinal-plants': 'Plantas medicinais',
  'melilotus': 'Melilotus',
  'melons': 'Melão',
  'membership_type': 'Tipo de Membro',
  'meslin': 'Martinho',
  'millet': 'Milheto',
  'mint': 'Menta',
  'mirabelles': 'Ameixeira amarela',
  'miscanthus': 'Capim-prateado',
  'mixed-cereal': 'Cereais consorciados',
  'monitoring-visit': 'Monitoramento',
  'monitoring-visit-file': 'monitoramento',
  'monitoring-with-review': 'Provisionamento/rastreamento (com gerenciamento, sem provisionamento)',
  'monitoring-with-review-provisioning': 'Provisionamento/rastreamento (com gerenciamento, com provisionamento)',
  'monitoring-without-review': 'Provisionamento/monitoramento (sem gerenciamento, sem provisionamento)',
  'monitoring-without-review-provisioning': 'Provisionamento/monitoramento (sem gerenciamento, com provisionamento)',
  'month_0': 'Jan',
  'month_1': 'Fev',
  'month_10': 'Nov',
  'month_11': 'Dez',
  'month_2': 'Mar',
  'month_3': 'Abr',
  'month_4': 'Mai',
  'month_5': 'Jun',
  'month_6': 'Jul',
  'month_7': 'Ago',
  'month_8': 'Set',
  'month_9': 'Out',
  'most-recent': 'Mais recente',
  'mustard': 'Mostarda',
  'nashi-pear': 'Pêra asiática',
  'nectarines': 'Ameixa',
  'noOfflineAreas': 'Para usar o GreenTriangle offline, é melhor transferir mapas para o seu dispositivo. Pressione Sim para fazer isso agora.',
  'none': 'Nenhuma',
  'normal': 'Normal',
  'notConnected': 'O aplicativo não está conectado à internet',
  'not_available': 'Não disponível',
  'not_planted': 'Não semeado',
  'nursery': 'Viveiro',
  'oats': 'Aveia',
  'oats-seeds': 'Aveia (grão)',
  'oilseeds': 'Oleaginosas',
  'olives': 'Oliveira',
  'onions': 'Cebolas',
  'oranges': 'Laranja',
  'orchard': 'Pomar (mais de 5 anos)',
  'orchard-pre-prod': 'Pomar (5 anos ou menos)',
  'orchards': 'Frutais',
  'oregano': 'Orégano',
  'other-aromatic-medicinal': 'Outras plantas aromáticas ou medicinais',
  'other-berries': 'Outros berries',
  'other-cereals': 'Outros cereais',
  'other-feed': 'Outras culturas forrageiras',
  'other-fruits': 'Outras frutas',
  'other-industry': 'Outras culturas industriais',
  'other-legumes': 'Outras leguminosas',
  'other-loss': 'Outras perdas',
  'other-oilseed': 'Outros produtos oleaginosos',
  'other-textile': 'Outras plantas têxteis',
  'other-vegetables': 'Outros vegetais',
  'parnsip': 'Pastinaga',
  'parsley': 'Salsa',
  'peach-blood': 'Pêssego sangue',
  'peach-flat': 'Pêssego chato',
  'peaches': 'Pêssego',
  'peaches-pre-prod': 'Pêssego (5 anos ou menos)',
  'peanuts': 'Amendoim',
  'pears': 'Pêras',
  'pears-pre-prod': 'Pera (5 anos ou menos)',
  'peas': 'Ervilhas',
  'peas-feed': 'Ervilhas (ração)',
  'peas-seeds': 'Ervilha (semente)',
  'peas-split': 'Ervilha',
  'pepper': 'Pimenta',
  'perennial': 'Perene',
  'perennial-crops': 'Culturas perenes',
  'persimmon': 'Caqui',
  'pest': 'Pragas',
  'pickle': 'Pepino (conserva)',
  'pineapples': 'Abacaxi',
  'pistachio': 'Pistache',
  'pleaseCheckForm': 'Verifique os campos do formulário e tente novamente.',
  'plum': 'Ameixa',
  'polyculture': 'Consociadas',
  'poor': 'Ruim',
  'poppy': 'Papoula',
  'potatoes': 'Batatas',
  'potatoes-industry': 'Batata (industria)',
  'potatoes-seeds': 'Batata (semente)',
  'potatoes-starch': 'Batata (almidão)',
  'potatoes-ware': 'Batata (consumo)',
  'precipitation': 'Precipitação total',
  'predicted-yield': 'Relação de produtividade prevista',
  'preinspection-none': 'Avaliação de risco',
  'preinspection-visit': 'Previa',
  'preinspection-visit-file': 'pré inspeção',
  'production-costs': 'Custos de Produção ($)',
  'pulses': 'Leguminosas',
  'pumpkin': 'Abóbora',
  'purification-costs': 'Custos de Purificação ($)',
  'quinces': 'Marmelo',
  'quinoa': 'Quinoa',
  'radishes': 'Rabanete',
  'rainstorm': 'Tempestade',
  'rapeseed': 'Colza',
  'rapeseed-feed': 'Colza forrageira',
  'rapeseed-seeds': 'Colza (semente híbrida)',
  'raspberry': 'Framboesa',
  'ray-grass': 'Ray-grass',
  'redcurrant': 'Groselha',
  'remaining-crops': 'Outras culturas',
  'replanting': 'Replantando',
  'resowing-costs': 'Costos de replantio',
  'rhubarb': 'Ruibarbo',
  'rice': 'Arroz',
  'ripening': '8. Maturação de frutos e sementes',
  'rocket': 'Rúcula',
  'root-crops': 'Tubérculos',
  'rosemary': 'Alecrim',
  'rutabaga': 'Couve-nabo',
  'rye': 'Centeio',
  'rye-seeds': 'Centeio (semente híbrida)',
  'safflower': 'Cártamo',
  'sage': 'Sálvia',
  'sainfoin': 'Sanfeno',
  'salad': 'Alface',
  'salsify': 'Barba de bode',
  'salvia': 'Sálvia',
  'sandstorm': 'Tormenta de areia',
  'satellite': 'Satélite',
  'satureja': 'Saturejas',
  'second-crop': 'Segunda colheita',
  'senescence': '9. Senescência ou início de dormência',
  'shallots': 'Chalota',
  'side-shoot': '2. Formação de rebentos laterais/afilhamento',
  'snow': 'Neve',
  'snow-peas': 'Ervilha vagem',
  'soil-moisture': 'Umidade do solo',
  'sorghum': 'Sorgo (grão)',
  'sorghum-feed': 'Sorgo forrageiro',
  'sorghum-seeds': 'Sorgo (semente híbrida)',
  'sorghum-silage': 'Sorgo (silagem)',
  'sorrel': 'Azedinha',
  'soybeans': 'Soja',
  'soybeans-seeds': 'Soja (grão)',
  'spelt': 'Trigo espelta',
  'spinach': 'Espinafre',
  'spring': 'Primavera',
  'sprouts': 'Couve de Bruxelas',
  'squash': 'Abóbora',
  'squash-max': 'Abóbora (Cucurbita maxima)',
  'static-data': 'Dados do país',
  'stevia': 'Stevia',
  'storm': 'Tempestade',
  'strawberry': 'Morango',
  'sugar-beet': 'Beterraba',
  'sugar-beet-feed': 'Beterraba forrageira',
  'sugar-beet-seeds': 'Beterraba (semente)',
  'sugar-cane': 'Cana de açúcar',
  'sugar-cane-feed': 'Cana-de-açúcar (ração)',
  'sunburn': 'Queimado pelo sol',
  'sunflower': 'Girassol',
  'sunflower-oilseed': 'Girasol',
  'sunflower-seeds': 'Girassol (semente)',
  'surface-temperature': 'Risco de Geada e Calor (superficie)',
  'switchgrass': 'Panicum',
  'syncDataDb': 'Sincronização de dados',
  'tarragon': 'Estragão',
  'temperature': 'Risco de geada e de calor',
  'thistle': 'Cardo',
  'thyme': 'Tomilho',
  'timothy-grass': 'Capim-timóteo',
  'tobacco': 'Tabaco',
  'tobacco-blond': 'Tabaco comum',
  'tobacco-brown': 'Tabaco escuro',
  'tobacco-burley': 'Tabaco Burley',
  'tobacco-virginie': 'Tabaco Viriginia',
  'tomatoes': 'Tomates',
  'tomatoes-processing': 'Tomate (industria)',
  'tons': 't',
  'tornado': 'Tornado',
  'triticale': 'Triticale',
  'triticale-seeds': 'Triticale (semente)',
  'true': 'Sim',
  'turmeric': 'Cúrcuma',
  'turnip': 'Nabo',
  'turnip-feed': 'Nabo forrageiro',
  'unit': 'unidade',
  'unknown': 'Desconhecido',
  'urtica': 'Urtigas',
  'user-admin': 'Gerenciador de usuários',
  'valerian': 'Valeriana',
  'validation-visit': 'Sinistro-Preliminar',
  'validation-visit-file': 'ocorrência',
  'validation-with-review': 'Ocorrência com a gerência',
  'validation-without-review': 'Ocorrência sem gestão',
  'vanilla': 'Baunilha',
  'vegetables': 'Legumes',
  'vegetation': 'Anomalia da vitalidade de plantas',
  'vegetative-growth': '3. Crescimento vegetativo',
  'verbena': 'Verbena',
  'very_good': 'Muito bom',
  'very_poor': 'Muito mal',
  'vetch': 'Fava',
  'vin-de-table': 'Uva (vinho)',
  'walnuts': 'Nogueira',
  'watermelon': 'Melancia',
  'weeds': 'Ervas daninhas',
  'wheat': 'Trigo (brando)',
  'wheat-einkorn': 'Trigo escanha',
  'wheat-feed': 'Trigo (ração)',
  'wheat-hard': 'Trigo (duro)',
  'wheat-hard-seeds': 'Trigo duro (semente híbrida)',
  'wheat-seeds': 'Trigo (semente híbrida)',
  'wheat-top': 'Trigo (Soft - moagem melhorada)',
  'wild-animals': 'Animais selvagens',
  'wind': 'Vento',
  'winter': 'Outono/Inverno',
  'yams': 'Inhame',
  'zucchini': 'Abobrinha',
  '-': '-',
  'UnknownLoss': 'Desconhecido (Causa das perdas)',
  'acres': 'ac',
  'added_by': 'Adicionado por',
  'added_on': 'Adicionado em',
  'arrobas-bra': '@/ha',
  'bags': 'sc',
  'bags-per-hectare': 'sc/ha',
  'bales-per-acre': 'Arrobas/acre',
  'bra_agree_with_result': 'Você concorda com o resultado apurado?',
  'bra_average': 'Média (tipo 2)',
  'bra_clay': 'Argiloso (tipo 3)',
  'bra_insured_facilitated': 'O segurado facilitou o processo de amostragem? (Se não, explique nos comentários)',
  'bra_insured_implemented_zarc_window': 'O segurado implantou a cultura dentro da janela de indicação do ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_last_liming_plastering_date': 'Quando foi feita a última calagem e gessagem na área segurada? (descrever a frequência da análise do solo, qual o perfil da camada do solo é trabalhada, tipo de calcário utilizado, faz uso de AP, já utilizou gesso na lavoura, etc)',
  'bra_losses_from_rnc': 'O perito constatou prejuízos por RNC? Explique os percentuais?',
  'bra_manual': 'Manual',
  'bra_mechanized': 'Mecanizada',
  'bra_necessary_to_subdivide': 'Foi necessário a subdivisão dos itens segurados? (Se sim, explique nos comentários)',
  'bra_planned_location': 'A lavoura foi implantada em local previsto? (Se não, explique nos comentários)',
  'bra_recommended_risk_location': 'A cultivar/variedade semeada é recomendada para o local do risco, conforme ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_sampling_type': 'Qual o tipo de amostragem utilizada para estimativa de produtividade da lavoura?',
  'bra_sandy': 'Arenoso (tipo 1)',
  'bra_soil_type': 'Tipo de solo',
  'bra_treatments_effective': 'Os tratos culturais foram efetivos no manejo da cultura?',
  'bra_uniform_management': 'O manejo empregado na lavoura segurada é igual das áreas de recurso próprio? (Se não, explique nos comentários)',
  'bra_visual': 'Visual',
  'brl': 'R$',
  'brl-per-bag': 'R$/sc',
  'bushels-per-acre': 'bu/ac',
  'centimeters': 'cm',
  'chf': 'Fr.',
  'claim_id': 'Alegar',
  'corn-grain-white': 'Milho (grão) (white)',
  'corn-grain-yellow': 'Milho (grão) (yellow)',
  'corn-silage-white': 'Milho (silagem) (white)',
  'corn-silage-yellow': 'Milho (silagem) (yellow)',
  'crop_id': 'Tipo de cultura',
  'custom-a': 'Vegetação Mediana do Município',
  'decitons-per-hectare': 'qx/ha',
  'development-cereals': '4. Desenvolvimento de partes de plantas que podem ser colhidas',
  'development-sorghum': '4. Desenvolvimento de partes de plantas que podem ser colhidas',
  'development-sugar-beet': '4. Desenvolvimento de partes de plantas que podem ser colhidas',
  'eur': '€',
  'eur-per-hectoliter': '€/hL',
  'eur-per-ton': '€/t',
  'farm_id': 'Fazenda',
  'feet': 'ft',
  'flowering-cereals': 'Floração',
  'flowering-corn': 'Floração',
  'flowering-fruits': 'Floração',
  'flowering-grapes': 'Floração',
  'flowering-legumes': 'Floração',
  'flowering-rapeseed': 'Floração',
  'flowering-sorghum': 'Floração',
  'flowering-soybeans': 'Floração',
  'flowering-sunflower': 'Floração',
  'fruit-development-cereals': '7. Desenvolvimento do fruto',
  'fruit-development-corn': '7. Desenvolvimento do fruto',
  'fruit-development-fruits': '7. Desenvolvimento do fruto',
  'fruit-development-grapes': '7. Desenvolvimento do fruto',
  'fruit-development-legumes': '7. Desenvolvimento do fruto',
  'fruit-development-rapeseed': '7. Desenvolvimento do fruto',
  'fruit-development-sorghum': '7. Desenvolvimento do fruto',
  'fruit-development-soybeans': '7. Desenvolvimento do fruto',
  'fruit-development-sunflower': '7. Desenvolvimento do fruto',
  'germination-cereals': 'Germinação',
  'germination-corn': 'Germinação',
  'germination-fruits': 'Germinação',
  'germination-grapes': 'Germinação',
  'germination-legumes': 'Germinação',
  'germination-rapeseed': 'Germinação',
  'germination-sorghum': 'Germinação',
  'germination-soybeans': 'Germinação',
  'germination-sugar-beet': 'Germinação',
  'germination-sunflower': 'Germinação',
  'grams': 'gr',
  'harvest_year': 'Ano da colheita',
  'hectares': 'ha',
  'hectoliters-per-hectare': 'hl/há (hectolitros por hectare)',
  'images': 'Fotos',
  'inches': 'in',
  'inflorescence-cereals': '5. Emergência da inflorescência / Espiga',
  'inflorescence-corn': '5. Emergência da inflorescência / Espiga',
  'inflorescence-fruits': '5. Emergência da inflorescência / Espiga',
  'inflorescence-grapes': '5. Emergência da inflorescência / Espiga',
  'inflorescence-legumes': '5. Emergência da inflorescência / Espiga',
  'inflorescence-rapeseed': '5. Emergência da inflorescência / Espiga',
  'inflorescence-sorghum': '5. Emergência da inflorescência / Espiga',
  'inflorescence-sunflower': '5. Emergência da inflorescência / Espiga',
  'kilograms': 'kg',
  'kilograms-per-hectare': 'kg/ha',
  'leaf-development-cereals': '1. Desenvolvimento da folha',
  'leaf-development-corn': '1. Desenvolvimento da folha',
  'leaf-development-fruits': '1. Desenvolvimento da folha',
  'leaf-development-grapes': '1. Desenvolvimento da folha',
  'leaf-development-legumes': '1. Desenvolvimento da folha',
  'leaf-development-rapeseed': '1. Desenvolvimento da folha',
  'leaf-development-sorghum': '1. Desenvolvimento da folha',
  'leaf-development-soybeans': '1. Desenvolvimento da folha',
  'leaf-development-sugar-beet': '1. Desenvolvimento da folha',
  'leaf-development-sunflower': '1. Desenvolvimento da folha',
  'loss_cause': 'Causa das perdas',
  'meters': 'm',
  'ounces': 'oz',
  'percent': '%',
  'pln': 'zł',
  'policy_id': 'Número da apólice',
  'policy_number': 'Número da apólice',
  'ripening-cereals': '8. Maturação de frutos e sementes',
  'ripening-corn': '8. Maturação de frutos e sementes',
  'ripening-fruits': '8. Maturação de frutos e sementes',
  'ripening-grapes': '8. Maturação de frutos e sementes',
  'ripening-legumes': '8. Maturação de frutos e sementes',
  'ripening-rapeseed': '8. Maturação de frutos e sementes',
  'ripening-sorghum': '8. Maturação de frutos e sementes',
  'ripening-soybeans': '8. Maturação de frutos e sementes',
  'ripening-sunflower': '8. Maturação de frutos e sementes',
  'senescence-fruits': '9. Senescência ou início de dormência',
  'senescence-grapes': '9. Senescência ou início de dormência',
  'senescence-sorghum': '9. Senescência ou início de dormência',
  'senescence-soybeans': '9. Senescência ou início de dormência',
  'senescence-sunflower': '9. Senescência ou início de dormência',
  'side-shoot-cereals': '2. Formação de rebentos laterais/afilhamento',
  'side-shoot-sorghum': '2. Formação de rebentos laterais/afilhamento',
  'square-centimeters': 'cm²',
  'square-feet': 'ft²',
  'square-inches': 'in²',
  'square-meters': 'm²',
  'thousand-kernel-weight-grams': 'Peso de 1000 grãos (gr)',
  'tons-per-acre': 't/ac',
  'tons-per-hectare': 't/ha',
  'units-per-acre': '/ac',
  'units-per-ft2': '/ft²',
  'units-per-hectare': '/ha',
  'units-per-m2': '/m²',
  'usd': '$',
  'user-admin-static-data': 'Dados do país',
  'user_group': 'Portfólio',
  'vegetative-growth-cereals': '3. Crescimento vegetativo',
  'vegetative-growth-corn': '3. Crescimento vegetativo',
  'vegetative-growth-fruits': '3. Crescimento vegetativo',
  'vegetative-growth-legumes': '3. Crescimento vegetativo',
  'vegetative-growth-rapeseed': '3. Crescimento vegetativo',
  'vegetative-growth-sorghum': '3. Crescimento vegetativo',
  'vegetative-growth-soybeans': '3. Crescimento vegetativo',
  'vegetative-growth-sugar-beet': '3. Crescimento vegetativo',
  'visit_type': 'Tipo de vistoria'
} as const;

export default function t(k: I18nSimpleKey|I18nParametric): string {
  if (typeof k == 'string' || typeof k == 'boolean') { return msgs[k]; }
  if (k === null || k == undefined) { console.error('t called with null or undefined!'); return '(∅)'; }
  switch (k.type) {
    case 'AddFarmTimeseriesMaxLimitError': return `Você atingiu o limite de ${k.count} farms em cache off-line. Exclua um para continuar.`;
    case 'AdjusterSampledFarm': return `Fazenda ${k.added_by} de amostra ${k.farm_name}`;
    case 'AllocateRemainingHarvestArea': return `Você pode alocar outro ${intl_num_2(k.remainingArea)}` + t(k.unit) + `.`;
    case 'AreaIsKm2': return `Esta área tem ${intl_num(k.area)} km² (aproximadamente ${intl_int(k.mb)} MB).`;
    case 'CurFieldPolicyDesc': return `Apólice do talhão atual: ${k.policy_number}`;
    case 'CurPortfolio': return `Portfólio: ${k.user_group}`;
    case 'DeleteAlreadyAssignedVisitWarning': return `Você está tentando excluir uma visita atribuída a ${k.count} outros LAs também.`;
    case 'DeletedUserX': return `Usuário excluído "${k.user_name}"`;
    case 'DistKmAway': return `${intl_num(k.dist_km)} km de distância`;
    case 'DownloadExplain': return `Selecione uma área para baixar para uso off-line. Área máxima: ${intl_int(k.limit_km_2)} km². Se necessário, faça o download de várias zonas.`;
    case 'DuplicateFieldId': return `O talhão ${k.external_field_id} já foi adicionado.`;
    case 'DuplicateSubplotWarning': return `Já existe um Sub-talhão ID: ${k.external_harvest_id} para esta fazenda e Ano da colheita. Para adicionar uma amostra, altere sua seleção na página anterior ou escolha outro ID de subparcela.`;
    case 'EarsDetectedCount': return `${k.count} ouvidos detectados`;
    case 'ErrorDeletingUserXY': return `Erro ao excluir "${k.user_name}": ${k.error_message}`;
    case 'EstimatedYieldOverrideInfo': return `O rendimento estimado foi modificado pela última vez em ${k.date}. Observe que essa substituição está substituindo o rendimento estimado agregado das amostras abaixo.`;
    case 'EstimatedYieldValueUnit': return `Estimado: ${intl_num_2(k.val)}` + t(k.unit);
    case 'ExistingHarvestContainsSamples': return `A colheita existente contém ${k.count} amostra(s).`;
    case 'ExoCropUnsupported': return `Este recurso atualmente não suporta ` + t(k.crop) + `.`;
    case 'FeasibleYieldValueUnit': return `Viável: ${intl_num_2(k.val)}` + t(k.unit);
    case 'FieldFormPickedOneOfMany': return `Aviso: esta amostra será adicionada apenas ao primeiro dos ${intl_int(k.numFields)} adicionados.`;
    case 'FileSizeTooBig': return `Este arquivo é muito grande. Selecione um arquivo com menos de ${k.file_size}MB.`;
    case 'ImageLocationTooFar': return `A imagem #${k.imageIdx} foi tirada ${intl_num(k.distanceMeters)}` + t(k.unit) + ` do seu local de amostragem.`;
    case 'InsuredYieldValueUnit': return `Segurado: ${intl_num_2(k.val)}` + t(k.unit);
    case 'InvalidBoolError': return `Este valor não pôde ser analisado como um valor booleano: ${k.val}`;
    case 'LossValue': return `Perda: ${intl_num(k.yield_loss_pct)}%`;
    case 'LossesMismatchWarning': return `A soma das perdas é muito grande: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` acima da estimativa.`;
    case 'LossesToAllocate': return `Perdas a alocar: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` de ${intl_num_2(k.total)}` + t(k.unit) + ` perda total.`;
    case 'NFarmsSelected': return `${intl_int(k.numFarms)} fazendas selecionadas`;
    case 'NPoliciesSelected': return `${intl_int(k.numPolicies)} apólices selecionadas`;
    case 'NoAppInstalledToViewFile': return `Nenhum aplicativo instalado para visualizar ${k.file_name}`;
    case 'NoDeleteClaim': return `Antes de excluir esta reivindicação, você precisa excluir ${intl_int(k.visitCount)} visitas.`;
    case 'NoDeleteFarm': return `Antes de excluir este farm, você precisa excluir seus campos ${intl_int(k.fieldCount)}, amostras ${intl_int(k.sampleCount)} e declarações ${intl_int(k.claimCount)}.`;
    case 'NoDeleteField': return `Antes de eliminar este talhão, precisa de eliminar as suas ${intl_int(k.sampleCount)} amostras.`;
    case 'NoDeleteHarvest': return `Antes de eliminar a colheita, deve eliminar as ${intl_int(k.sampleCount)} amostras.`;
    case 'NoDeletePolicy': return `Antes de excluir esta política, você precisa excluir suas reivindicações ${intl_int(k.claimCount)}.`;
    case 'NonUniqueFarm': return `Foram detectadas duas fazendas para ${k.key}, mas com detalhes diferentes: '${k.x1}' vs '${k.x2}'.`;
    case 'NumPhotos': return `${intl_int(k.num_photos)} fotos`;
    case 'OfflineAreaDownloadMore': return `Restam ${k.count} áreas offline.`;
    case 'OfflineAreaMaxLimit': return `Você atingiu o limite de ${k.count} áreas. Desmarque algumas áreas para continuar.`;
    case 'PasswordResetSent': return `Um e-mail de redefinição foi enviado para ${k.email}`;
    case 'PlantDensityValueUnit': return `Densidade de plantas: ${intl_num(k.val)}` + t(k.unit);
    case 'PolicyDesc': return `Apólice ${k.policy_number}`;
    case 'ReportContainsTooManyFields': return `Este reporte pode ser muito pasado, com ${intl_int(k.numFields)} talhões. Considere selecionar uma sub-amostra de datas e culturas.`;
    case 'ReportNoHarvests': return `Esta fazenda possui ${intl_int(k.numFields)} talhões, mas não possui quaisquer informações de cultura para apresentar!`;
    case 'ResetMsg': return `Um e-mail lhe foi enviado para ${k.email}`;
    case 'SampleAbrv': return `${intl_int(k.count)} s.`;
    case 'SampleLocationTooFar': return `O local da amostra é ${intl_num_2(k.val)}` + t(k.unit) + ` do local de inicio. Caso quiser usar a sua localização presione "Utilizar a minha localização" acima.`;
    case 'SampleYieldWarning': return `Favor, preencha a ${k.column1} ou a ${k.column2}, se possível.`;
    case 'SearchAddTipDesc': return `Se deseja pesquisar vários termos (por exemplo, colheita de trigo no portfólio ${k.firstUserGroup}), pode clicar ikonę ⊕ para adicionar um termo adicional.`;
    case 'SearchExampleDescUserGroup': return `Introduza um nome de portfólio (por exemplo, ${k.firstUserGroup}) para mostrar todos os elementos desse portfólio`;
    case 'SearchFoundCount': return `Encontradas ${intl_int(k.farmCount)} fazendas, ${intl_int(k.fieldCount)} talhões e ${intl_int(k.sampleCount)} amostras.`;
    case 'SearchInfoText': return `Pode encontrar fazendas, talhões, amostras e apólices através de uma pesquisa dos mesmos aqui. Se deseja pesquisar por vários termos (por exemplo, colheita de trigo no portfólio ${k.firstUserGroup}), pode clicar no ícone ⊕. Cada pesquisa atuará como um filtro que restringe ainda mais os resultados. Veja os exemplos abaixo para obter algumas formas de pesquisar.`;
    case 'SearchResultAddedBy': return `amostras adicionadas por ${k.id}`;
    case 'SearchResultAddress': return `Centre o mapa em ${k.address}`;
    case 'SearchResultCropCondition': return `amostras de culturas com condição ${k.id}`;
    case 'SearchResultFarm': return `fazenda ${k.farmDesc}`;
    case 'SearchResultHarvestCrop': return `elementos por ${k.id}`;
    case 'SearchResultHarvestSeason': return `elementos para colheitas em ` + t(k.id);
    case 'SearchResultHarvestYear': return `elementos para colheitas de ${k.id}`;
    case 'SearchResultUserGroup': return `Portfólio ${k.id}`;
    case 'SelectCount': return `${k.count} selecionados`;
    case 'SortBy': return `Ordenar ${k.column} ` + t(k.id);
    case 'SureAddAnotherHarvest': return `Tem a certeza de que deseja adicionar outra colheita a este talhão, além de ${k.harvestDesc}?`;
    case 'SureDeleteFile': return `Tem certeza de que deseja excluir ${k.file_name} ?`;
    case 'SureDeletePolicy': return `Tem a certeza de que deseja eliminar esta apólice? Esta apólice será removido de ${intl_int(k.fieldCount)} talhões e de ${intl_int(k.sampleCount)} amostras. Contudo, estes não serão eliminados.`;
    case 'SureRemoveFarm': return `Tem a certeza de que deseja remover esta fazenda e os seus ${intl_int(k.fieldCount)} talhões?`;
    case 'SureRemoveSelectedOfflineFarms': return `Tem certeza de que deseja remover o acesso off-line de ${k.count} fazendas selecionadas?`;
    case 'TelepacReportEmail': return `Um reporte foi enviado ao seu ${k.email}, também pode baixa-lo aqui.`;
    case 'TotalAffectedAreaHarvest': return `A área total afetada para esta colheita será de ${intl_num_2(k.newArea)}` + t(k.unit) + ` sobre uma área de colheita de ${intl_num_2(k.harvestArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt': return `A área afetada atual (amostras nas últimas duas semanas) para esta safra é: ${intl_num_2(k.curArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt2': return `Estas são todas as áreas afetadas em esta safra: ${k.arr}.`;
    case 'TotalLossPct': return `Perda total: ${intl_num(k.totalLossPct)}%`;
    case 'TotalSubplotAreaBiggerThanField': return `A área total da subparcela (${intl_num_2(k.total_subplot_area)} ha) é maior que a área do campo (${intl_num_2(k.field_area)} ha).`;
    case 'UpdateHarvestAreaMsg': return `Isso atualizará a Area da Cultura da colheita atual (${k.desc}) para ${intl_num_2(k.val)}` + t(k.unit) + `.`;
    case 'UpgradeFieldHarvest': return `Este campo tem atualmente uma colheita de ${k.originalHarvest}, que será substituída por uma colheita de ${k.upgradedHarvest}, para corresponder ao contrato.`;
    case 'WarnHarvestAlreadyInUse': return `Salvar suas alterações nesta colheita existente afetaria ${intl_int(k.num_samples)} amostras já existentes.`;
    case 'WarningDupePolicyNumber': return `Observação: já existe uma apólice com o número ${k.policy_number}`;
    case 'YieldMismatchWarning': return `Nem todos os danos são contabilizados: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` faltando.`;
    case 'YourAreGoingToDeleteUsers': return `Você excluirá ${intl_int(k.num_users)} usuários:`;
    case 'downloadingLayers': return `Fazendo o download de ${intl_int(k.count)} imagens de satélite para uso off-line.`;
    case 'uploadingImages': return `Enviando fotos para o banco de dados  (${intl_int(k.left)} restante).`;
    case 'AreaUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'AreaUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
    case 'FarmDesc': return `${k.farm_name}, ${k.address}`;
    case 'FarmDesc_reference': return `${k.farm_name}, ${k.address} (${k.external_farm_id})`;
    case 'SampleDesc': return `${k.sample_date}: ${intl_num(k.estimated_yield_val)}` + t(k.estimated_yield_unit) + ` ` + t(k.crop_condition);
    case 'ValueUnit': return `${intl_num(k.val)}` + t(k.unit);
    case 'ValueUnitRange': return `${intl_num(k.min_val)}-${intl_num(k.max_val)}` + t(k.unit);
    case 'YieldUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'YieldUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
  }
}