import {TeamOutlined} from '@ant-design/icons';
import {Col, PageHeader, Row} from 'antd';
import React from 'react';
import {useApis} from '../apis/ApisContext';
import {AdminUserEntityTable} from '../components/AdminUserEntityTable';
import {useAdminInfo} from '../util/admin-util';
import './UserAdminPage.css';

const UserAdminPage: React.FC = () => {
  const {t} = useApis();
  return (
    <div className="admin-page">
      <Row>
        <Col span={24}>
          <PageHeader
            title={t('UserAdministration')}
            subTitle={t('ManageUserAccountAndSettings')}
            className="no-print" //Hide header when printing
            avatar={{icon: <TeamOutlined />}}
          />
        </Col>
      </Row>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <AdminUserEntityTable />
        </Col>
      </Row>
    </div>
  );
};

export default () => {
  const admin = useAdminInfo();
  if (!admin.isUserAdmin) {
    return null;
  }
  return <UserAdminPage />;
};
