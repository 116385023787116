// An element for selecting the user's group.
import React from 'react';
import {FormyI} from '../../../src/Formy';
import {UserGroupMembership} from '../../../src/models/interfaces';
import {getTransitiveUserGroupGrantees} from '../../../src/selectors/userGroups';
import {unique} from '../../../src/util/arr-util';
import {Apis} from '../apis/Apis';
import {ApisContext} from '../apis/ApisContext';
import {FormyMultiEnum} from './FormyEnum';

interface BaseF {
  editors: string[];
  user_group: null | string;
}

interface FormyEditorsSelectorProps<F extends BaseF> {
  formy: FormyI<F>;
  user_group: null | string;
}

interface FormyEditorsSelectorState {
  memberships: null | UserGroupMembership[];
  editors: null | [string, string][];
}

class InnerFormyEditorsSelector<F extends BaseF> extends React.Component<
  FormyEditorsSelectorProps<F>,
  FormyEditorsSelectorState
> {
  static contextType = ApisContext;
  context!: Apis;
  state: FormyEditorsSelectorState = {memberships: null, editors: null};

  static cachedMemberships: null | UserGroupMembership[] = null;

  static async getMemberships(apis: Apis): Promise<UserGroupMembership[]> {
    if (!this.cachedMemberships) {
      this.cachedMemberships = await apis.authedFetcher({method: 'GET', path: 'api/user_group_membership'});
    }
    return this.cachedMemberships ?? [];
  }

  async componentDidMount() {
    this.setState({memberships: await InnerFormyEditorsSelector.getMemberships(this.context)});
  }

  componentDidUpdate(prevProps: Readonly<FormyEditorsSelectorProps<F>>) {
    if (this.state.memberships && (this.state.editors == null || prevProps.user_group != this.props.user_group)) {
      if (!this.props.user_group) {
        this.setState({editors: []});
      } else {
        const state = this.context.store.getState();
        // We only show users that belong to this user group, or any other group that it grants to.
        const groups = getTransitiveUserGroupGrantees(state)[this.props.user_group];
        const emails = unique(this.state.memberships.filter(x => groups?.has(x.user_group)).map(x => x.email));
        emails.sort();
        this.setState({editors: emails.map(x => [`@${x}`, x])});
      }
    }
  }

  render() {
    return (
      <span className="formy-multiselect">
        <FormyMultiEnum<null | string, F, 'editors'>
          options={this.state.editors ?? []}
          selectMsg="Select"
          field="editors"
          formy={this.props.formy}
        />
      </span>
    );
  }
}

export default class FormyEditorsSelector<F extends BaseF> extends React.PureComponent<{formy: FormyI<F>}> {
  value = this.props.formy.watchValue('user_group', this);

  render() {
    return <InnerFormyEditorsSelector formy={this.props.formy} user_group={this.value()} />;
  }
}
