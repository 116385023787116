import {HighlightOutlined} from '@ant-design/icons';
import {Button, Col, PageHeader, Row, Tree, notification} from 'antd';
import {Key} from 'antd/lib/table/interface';
import {DataNode, EventDataNode} from 'antd/lib/tree';
import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {FetcherFunc} from '../../../src/FetcherFunc';
import {useFormyValue, useSyncFormy} from '../../../src/Formy/hooks';
import {I18nSimpleKey} from '../../../src/i18n/i18n';
import {VisitCustomColumns} from '../../../src/models/CustomColumns';
import {
  Claim,
  ClaimDamage,
  Farm,
  Field,
  Harvest,
  HarvestYear,
  Request,
  Sample,
  VisitLite,
} from '../../../src/models/interfaces';
import {Uuid} from '../../../src/models/types';
import {getInseeCodeMapping} from '../../../src/report/custom-mapping';
import {getCrops} from '../../../src/selectors/crops';
import {getCurUserEmail} from '../../../src/selectors/dbMeta';
import {metricUnitSystem} from '../../../src/selectors/units';
import {farmDesc, fieldDesc, harvestDesc, visitDesc} from '../../../src/text/desc';
import {getSampleDetails} from '../../../src/text/line';
import {unique} from '../../../src/util/arr-util';
import {getPostgrestQueryParams} from '../../../src/util/postgrest-query';
import FormyEntitySelector from '../Formy/FormyEntitySelector';
import {Label} from '../Formy/FormyLabel';
import {useApis} from '../apis/ApisContext';
import SpinningDots from '../components/SpinningDots';
import {useAdminInfo} from '../util/admin-util';

type Data = {
  farm: Farm;
  fields: Field[];
  harvests: Harvest[];
  samples: Sample[];
  claims: Claim[];
  claim_damages: ClaimDamage[];
  visits: VisitLite[];
  requests: Request[];
};

const Section: React.FC<{title: I18nSimpleKey} & PropsWithChildren> = ({title, children}) => {
  return (
    <section>
      <h2>{title}</h2>
      {children}
    </section>
  );
};

function selectAllKeys(data: DataNode[]): (string | number)[] {
  return data.flatMap(d => [d.key, ...selectAllKeys(d.children ?? [])]);
}

const fetchCache: Record<string, Data[]> = {};

const fetch = async (authedFetcher: FetcherFunc, farm_id: string): Promise<Data[]> => {
  if (fetchCache[farm_id]) {
    return fetchCache[farm_id];
  }
  const farm = (await authedFetcher({
    method: 'GET',
    path: `api/farm`,
    params: getPostgrestQueryParams({
      column: 'farm_id',
      operator: 'eq',
      value: farm_id,
    }),
  })) as Farm[];
  if (!farm.length) {
    return [];
  }
  const fields = (await authedFetcher({
    method: 'GET',
    path: `api/field`,
    params: getPostgrestQueryParams({
      column: 'farm_id',
      operator: 'eq',
      value: farm_id,
    }),
  })) as Field[];
  const farmHarvests = (await authedFetcher({
    method: 'GET',
    path: `api/harvest`,
    params: getPostgrestQueryParams({
      column: 'farm_id',
      operator: 'eq',
      value: farm_id,
    }),
  })) as Harvest[];
  const fieldHarvests = (await authedFetcher({
    method: 'GET',
    path: `api/harvest`,
    params: getPostgrestQueryParams({
      column: 'field_id',
      operator: 'in',
      value: fields.map(f => f.field_id),
    }),
  })) as Harvest[];
  const harvests = [...farmHarvests, ...fieldHarvests];
  const samples = (await authedFetcher({
    method: 'GET',
    path: `api/sample`,
    params: getPostgrestQueryParams({
      column: 'harvest_id',
      operator: 'in',
      value: harvests.map(h => h.harvest_id),
    }),
  })) as Sample[];
  const claims = (await authedFetcher({
    method: 'GET',
    path: 'api/claim',
    params: getPostgrestQueryParams({
      column: 'farm_id',
      operator: 'eq',
      value: farm_id,
    }),
  })) as Claim[];
  const claim_damages = (await authedFetcher({
    method: 'GET',
    path: 'api/claim_damage',
    params: getPostgrestQueryParams({
      column: 'claim_id',
      operator: 'in',
      value: claims.map(c => c.claim_id),
    }),
  })) as ClaimDamage[];
  const visits = (await authedFetcher({
    method: 'GET',
    path: 'api/visit_lite',
    params: getPostgrestQueryParams({
      column: 'claim_id',
      operator: 'in',
      value: claims.map(c => c.claim_id),
    }),
  })) as VisitLite[];
  const requests = (await authedFetcher({
    method: 'GET',
    path: 'api/request',
    params: getPostgrestQueryParams({
      or: [
        {
          // Get incoming missions for this farm
          and: [
            {
              column: `request_type`,
              operator: 'eq',
              value: 'mission-import',
            },
            {
              column: 'user_group',
              operator: 'eq',
              value: farm[0].user_group,
            },
            {
              column: 'request->missionSpreadsheet->header->IdentificationDeLAssure->>Nom',
              operator: 'eq',
              value: farm[0].farm_name,
            },
          ],
        },
        {
          // Get processed mission reports
          column: `request->>visit_id`,
          operator: 'in',
          value: visits.map(v => v.visit_id),
        },
      ],
    }),
  })) as Request[];

  return (fetchCache[farm_id] = farm.map(farm => ({
    farm,
    fields,
    harvests,
    samples,
    claims,
    claim_damages,
    visits,
    requests,
  })));
};

function enhanceVisit(visit: VisitLite, harvests: Harvest[], samples: Sample[]): VisitLite {
  try {
    const visitCustomColumns = new VisitCustomColumns(visit.custom_columns);
    if (visitCustomColumns.grpm && visitCustomColumns.signedFarmReportValues) {
      const inseeCodeMappings = getInseeCodeMapping(
        visitCustomColumns.grpm.inseeShapes,
        visitCustomColumns.signedFarmReportValues,
      );
      console.info({inseeCodeMappings, samples, harvests});
      const unmappedHarvest = harvests.filter(h =>
        Object.values(inseeCodeMappings).every(m => !m.includes(h.harvest_id)),
      );
      const unmappedSamples = samples.filter(s => unmappedHarvest.some(h => h.harvest_id === s.harvest_id));
      visit.metadata.enhanced = {
        inseeCodeMappings: inseeCodeMappings,
        unmappedHarvest,
        unmappedSamples,
      };
    }
    return visit;
  } catch (e) {
    console.error(e);
  }
  return visit;
}

const ReviewFarmPage: React.FC = () => {
  const {t, authedFetcher} = useApis();
  const formy = useSyncFormy<{
    farm_id: Uuid | null;
  }>(
    'edit',
    () => ({farm_id: null}),
    t,
    async () => console.info('submit'),
    () => ({farm_id: false}),
    null,
  );
  const farm_id = useFormyValue(formy, 'farm_id');

  const indexedCrops = useSelector(getCrops);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [details, setDetails] = useState<any>(null);
  const [expandedKeys, setExpandedKeys] = useState<(string | number)[]>([]);
  const treeData: DataNode[] = useMemo(() => {
    if (!data?.length) {
      return [];
    }
    return data.map(d => {
      const fields = d.fields.filter(f => f.farm_id === d.farm.farm_id);
      const harvests = d.harvests.filter(
        h =>
          h.farm_id === d.farm.farm_id ||
          fields.some(f => f.field_id === h.field_id) ||
          d.visits.some(v => v.harvest_ids.includes(h.harvest_id)),
      );
      const samples = d.samples.filter(s => harvests.some(h => h.harvest_id === s.harvest_id));
      const harvestYears: HarvestYear[] = unique(harvests.map(h => h.harvest_year as HarvestYear)).sort();
      const claims: {
        claim: Claim;
        claim_damages: ClaimDamage[];
        visits: VisitLite[];
        harvest_year: HarvestYear;
      }[] = d.claims
        .filter(c => c.farm_id === d.farm.farm_id)
        .map(claim => {
          const claim_damages = d.claim_damages.filter(cd => cd.claim_id === claim.claim_id);
          const visits = d.visits.filter(v => v.claim_id === claim.claim_id);
          return {
            claim,
            claim_damages,
            visits,
            harvest_year: claim.harvest_year,
          };
        });
      return {
        title: `Farm: ${farmDesc(t, d.farm)}`,
        key: JSON.stringify({farm_id: d.farm.farm_id}),
        data: d.farm,
        children: harvestYears.map(harvest_year => ({
          title: harvest_year,
          key: JSON.stringify({farm_id: d.farm.farm_id, harvest_year}),
          selectable: false,
          children: [
            ...claims
              .filter(c => c.harvest_year === harvest_year)
              .map(c => ({
                title: `Claim ${c.claim.external_claim_id}/${c.claim.status}`,
                key: JSON.stringify({farm_id: d.farm.farm_id, harvest_year, claim_id: c.claim.claim_id}),
                data: c.claim,
                children: [
                  ...c.claim_damages.map(cd => {
                    const cdHarvest = harvests.find(h => h.harvest_id === cd.harvest_id);
                    return {
                      title: `Claim Damage: ${cdHarvest?.harvest_year}: ${cdHarvest?.crop_id}`,
                      key: JSON.stringify({
                        farm_id: d.farm.farm_id,
                        harvest_year,
                        claim_id: c.claim.claim_id,
                        claim_damage_id: cd.claim_damage_id,
                      }),
                      data: cd,
                      children: [],
                    };
                  }),
                  ...c.visits
                    .map(visit => enhanceVisit(visit, harvests, samples))
                    .map(v => ({
                      title: `Visit: ${visitDesc(
                        t,
                        indexedCrops,
                        v,
                        c.claim,
                        d.farm,
                        harvests.filter(h => v.harvest_ids.includes(h.harvest_id)),
                      )}`,
                      key: JSON.stringify({
                        farm_id: d.farm.farm_id,
                        harvest_year,
                        claim_id: c.claim.claim_id,
                        visit_id: v.visit_id,
                      }),
                      data: v,
                      children: d.requests
                        .filter(r => r.request_type === 'visit-report' && r.request.visit_id === v.visit_id)
                        .map(r => ({
                          title: `Request [visit-report]: ${r.updated_at}`,
                          key: JSON.stringify({
                            farm_id: d.farm.farm_id,
                            harvest_year,
                            claim_id: c.claim.claim_id,
                            visit_id: v.visit_id,
                            request_id: r.request_id,
                          }),
                          data: r,
                          children: [],
                        })),
                    })),
                ],
              })),
            ...d.harvests
              .filter(h => !h.field_id && h.harvest_year === harvest_year)
              .map(h => ({
                title: `Farm Harvest: ${harvestDesc(t, indexedCrops, h as Harvest, null, [], null, true)}`,
                key: JSON.stringify({farm_id: d.farm.farm_id, harvest_year, harvest_id: h.harvest_id}),
                data: h,
                children: d.samples
                  .filter(s => s.harvest_id === h.harvest_id)
                  .map(s => ({
                    title: `Sample: ${getSampleDetails(t, metricUnitSystem, s as unknown as Sample, null).join(', ')}`,
                    key: JSON.stringify({
                      farm_id: d.farm.farm_id,
                      harvest_year,
                      harvest_id: h.harvest_id,
                      sample_id: s.sample_id,
                    }),
                    data: s,
                    children: [],
                  })),
              })),
            {
              title: `Fields (${d.fields.length})`,
              key: JSON.stringify({farm_id: d.farm.farm_id, harvest_year, fields: true}),
              children: d.fields
                .map(f => {
                  const harvest =
                    (d.harvests.find(h => h.field_id === f.field_id && h.harvest_year === harvest_year) as Harvest) ??
                    null;
                  return {
                    title: `${fieldDesc(t, indexedCrops, [], f as unknown as Field, harvest, null, null, false)}`,
                    key: JSON.stringify({farm_id: d.farm.farm_id, harvest_year, field_id: f.field_id}),
                    data: f,
                    children: d.harvests
                      .filter(h => h.field_id === f.field_id && h.harvest_year === harvest_year)
                      .map(h => ({
                        title: `Field Harvest: ${harvestDesc(t, indexedCrops, h as Harvest, null, [], null, true)}`,
                        key: JSON.stringify({
                          farm_id: d.farm.farm_id,
                          harvest_year,
                          field_id: f.field_id,
                          harvest_id: h.harvest_id,
                        }),
                        data: h,
                        children: d.samples
                          .filter(s => s.harvest_id === h.harvest_id)
                          .map(s => ({
                            title: `Sample: ${getSampleDetails(t, metricUnitSystem, s as unknown as Sample, null).join(
                              ', ',
                            )}`,
                            key: JSON.stringify({
                              farm_id: d.farm.farm_id,
                              harvest_year,
                              field_id: f.field_id,
                              harvest_id: h.harvest_id,
                              sample_id: s.sample_id,
                            }),
                            data: s,
                            children: [],
                          })),
                      })),
                  };
                })
                .sort((a, b) => b.title.localeCompare(a.title)),
            },
            {
              title: `Mission Imports (${
                d.requests.filter(
                  r =>
                    r.request_type === 'mission-import' &&
                    r.request.missionSpreadsheet.header.DateDeCreation.startsWith(harvest_year),
                ).length
              })`,
              key: JSON.stringify({farm_id: d.farm.farm_id, harvest_year, missions: true}),
              children: d.requests
                .filter(
                  r =>
                    r.request_type === 'mission-import' &&
                    r.request.missionSpreadsheet.header.DateDeCreation.startsWith(harvest_year),
                )
                .map(r => ({
                  title: `${r.updated_at}`,
                  key: JSON.stringify({farm_id: d.farm.farm_id, harvest_year, missions: true, request: r.request_id}),
                  data: r,
                  children: [],
                }))
                .sort((a, b) => b.title.localeCompare(a.title)),
            },
          ],
        })),
      };
    });
  }, [data, indexedCrops, t]);
  useEffect(() => {
    setExpandedKeys(selectAllKeys(treeData));
  }, [treeData]);
  useEffect(() => {
    if (!farm_id) {
      return;
    }
    setLoading(true);
    setData([]);
    setDetails(null);
    fetch(authedFetcher, farm_id)
      .then(setData)
      .finally(() => setLoading(false));
  }, [authedFetcher, farm_id]);

  return (
    <div className="admin-page">
      <Row>
        <Col span={24}>
          <PageHeader
            title={'Review Farm'}
            subTitle={''}
            className="no-print" //Hide header when printing
            avatar={{icon: <HighlightOutlined />}}
          />
        </Col>
      </Row>
      <Row gutter={[20, 10]}>
        <Col span={24}>
          <Label>{t('Farm')}:</Label>
          <FormyEntitySelector field="farm_id" formy={formy} entityType="farm" onNewEntity={null} />
        </Col>
      </Row>
      <Row gutter={[20, 10]}>
        <Col span={12}>
          <h2>
            {t('Details')}
            <Button onClick={() => setExpandedKeys(selectAllKeys(treeData))}>Expand all</Button>
            <Button onClick={() => setExpandedKeys([])}>Collapse all</Button>
          </h2>
          {loading ? (
            <div>
              <SpinningDots size={20} />
            </div>
          ) : null}
          <Tree
            checkable={false}
            defaultExpandAll={true}
            expandedKeys={expandedKeys}
            onExpand={setExpandedKeys}
            treeData={treeData}
            onSelect={(selectedKeys, e) => {
              setDetails((e.node as any).data);
            }}
          />
        </Col>
        <Col span={12}>
          <Section title="Details">
            <DebugEntityViewer entity={details} />
          </Section>
        </Col>
      </Row>
      <Row gutter={[20, 10]}>
        <Col span={24}>
          <details>
            <summary>Run this query in any environment</summary>
            <pre>
              {`select row_to_json(farm)                                                                                                      as farm
                       , (select json_agg(row (field_id, added_on, added_by, updated_at, farm_id, external_field_id, field_location, user_location, comments, field_area, metadata, custom_columns, region_id)::api.field)
                          from data.field
                          where field.farm_id = farm.farm_id)                                                                                   as fields
                       , (select json_agg(harvest.*)
                          from data.harvest
                          where harvest.farm_id = farm.farm_id
                             or harvest.field_id in
                                (select field_id from data.field where field.farm_id = farm.farm_id))                                           as harvests
                       , (select json_agg(sample.*)
                          from data.sample
                          where sample.harvest_id in (select harvest_id
                                                      from data.harvest
                                                      where harvest.farm_id = farm.farm_id
                                                         or harvest.field_id in
                                                            (select field_id from data.field where field.farm_id = farm.farm_id)))              as samples
                       , (select json_agg(claim.*)
                          from data.claim
                          where claim.farm_id = farm.farm_id)                                                                                   as claims
                       , (select json_agg(claim_damage.*)
                          from data.claim_damage
                          where claim_damage.claim_id in
                                (select claim_id from data.claim where claim.farm_id = farm.farm_id))                                           as claim_damages
                       , (select json_agg(row (visit_id, added_on, added_by, sample_dates, visit_type, comments, withdrawal, signed_by_farmer, updated_at, closed, metadata, custom_columns, closed_on, external_visit_id, withdrawal_crop_ids, signature_crop_ids, claim_id, visit_date, visit_report_uri, harvest_ids)::api.visit_lite)
                          from data.visit
                          where visit.claim_id in
                                (select claim_id from data.claim where claim.farm_id = farm.farm_id))                                           as visits
                       , (select json_agg(request.*)
                          from data.request
                          where (request ->> 'visit_id')::uuid in (select visit_id
                                                                   from data.visit
                                                                   where visit.claim_id in
                                                                         (select claim_id from data.claim where claim.farm_id = farm.farm_id))) as requests
                  from data.farm
                  where farm_name = 'EARL DE TRAOUQUET';`}
            </pre>
          </details>
          <details>
            <summary>Past the resulting JSON here</summary>
            <textarea onChange={e => setData(JSON.parse(e.currentTarget.value))}></textarea>
          </details>
        </Col>
      </Row>
    </div>
  );
};

const lifecycleKeys = ['added_on', 'updated_at', 'added_by'];
const customKeys = ['metadata', 'custom_columns'];

interface DebugEntityCategory {
  filter: (key: string) => boolean;
  label: string;
}

const DEFAULT_CATEGORIES: DebugEntityCategory[] = [
  {label: 'ids', filter: (key: string) => key.endsWith('_id')},
  {
    label: 'entity',
    filter: (key: string) => !key.endsWith('_id') && !lifecycleKeys.includes(key) && !customKeys.includes(key),
  },
  {label: 'lifecycle', filter: (key: string) => lifecycleKeys.includes(key)},
  {label: 'custom', filter: (key: string) => customKeys.includes(key)},
];

export const DebugEntityViewer: React.FC<{entity: Object}> = ({entity}) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(['/_entity', '/_lifecycle']);
  const treeData: DataNode[] = useMemo(() => getDataNodes(entity), [entity]);
  const copyToClipboard = useCallback(async (event: React.MouseEvent, node: EventDataNode<Object>) => {
    const getCopyText = (o: DataNode): string => {
      if (o.children?.length) {
        return `${o.title}\n${o.children.map(getCopyText).join('\n')}`;
      }
      return String(o.title ?? o.key);
    };
    try {
      const text = getCopyText(node);
      await navigator.clipboard.writeText(text);
      notification.open({
        message: 'Copied to clipboard',
        description: text,
        duration: 2,
      });
    } catch (error) {
      console.error('Could not write to clipboard', error);
    }
  }, []);
  return (
    <div>
      <Tree
        treeData={treeData}
        expandedKeys={expandedKeys}
        onExpand={setExpandedKeys}
        onDoubleClick={copyToClipboard}
      />
    </div>
  );
};

export const ReviewFarm: React.FC = () => {
  const admin = useAdminInfo();
  const userEmail = useSelector(getCurUserEmail);
  const isGtUser = userEmail.endsWith('@green-triangle.com');
  if (!admin.isUserAdmin || !isGtUser) {
    return null;
  }
  return <ReviewFarmPage />;
};

function getDataNodes(
  object: any,
  parentKey: Key = '',
  categories: DebugEntityCategory[] | false = DEFAULT_CATEGORIES,
): DataNode[] {
  if (object === null || object === false) {
    return [];
  }
  if (typeof object === 'string') {
    try {
      const obj = JSON.parse(object);
      return getDataNodes(obj, parentKey, false);
    } catch (e) {
      return [
        {
          title: object,
          key: parentKey + '/' + object,
          children: [],
        },
      ];
    }
  } else if (Array.isArray(object)) {
    if (object.every(o => typeof o !== 'object')) {
      return object.map((o, ix) => ({
        title: JSON.stringify(o),
        key: parentKey + '/' + String(ix),
        children: [],
      }));
    }
    return object.map((o, ix) => ({
      title: `[${ix}]`,
      key: parentKey + '/' + String(ix),
      children: getDataNodes(o, parentKey + '/' + String(ix), false),
    }));
  }
  if (!categories) {
    return Object.entries(object)
      .sort(nullsAndEmptyLast)
      .map(([key, value]) => {
        const hasChildren = Array.isArray(value) || typeof value === 'object';
        return {
          title: hasChildren ? key : `${key}: ${JSON.stringify(value)}`,
          key: parentKey + '/' + key,
          children: hasChildren ? getDataNodes(value, parentKey + '/' + key, false) : [],
        };
      });
  }
  return categories.map(({label, filter}) => {
    return {
      title: label,
      key: parentKey + '/_' + label,
      children: Object.entries(object)
        .filter(([key]) => filter(key))
        .sort(nullsAndEmptyLast)
        .map(([key, value]) => {
          const hasChildren = Array.isArray(value) || (value != null && typeof value === 'object');
          return {
            title: hasChildren ? key : `${key}: ${JSON.stringify(value)}`,
            key: parentKey + '/' + key,
            children: hasChildren ? getDataNodes(value, parentKey + '/' + key, false) : [],
          };
        }),
    };
  });
}

function nullsAndEmptyLast(a: [string, any], b: [string, any]): number {
  const nullOrEmpty = (v: any) => v === null || v.length === 0;
  if (nullOrEmpty(a[1]) && nullOrEmpty(b[1])) {
    return 0;
  }
  if (nullOrEmpty(a[1])) {
    return 1;
  }
  if (nullOrEmpty(b[1])) {
    return -1;
  }
  return a[0].localeCompare(b[0]);
}
